import { Helmet } from "react-helmet-async";
import { useTitle } from "../helper/functions";
import { pageTitle } from "../helper/globals";
import ProductImage from "../images/company/IMG_6243.webp"
import CreatureImage from "../images/creature-1.webp"
import FAQ from "../components/marketing/faq";

const faqs = [
  {
    question: "How do I remove poo from cloth diapers?",
    answer:
      "The fleece diaper lining is designed so that most solids will come off easily. Simply shake off as much solid waste as possible directly into the toilet, remove the diaper insert, and rinse the diaper under running water or using a diaper sprayer. You can also use an old dish brush or silicone spatula to remove any residue. For babies that are exclusively breast-fed you don’t have remove the milky solids, because their poop is water-soluble! For easier clean-up of pasty or solid poo, we recommend using our disposable liners – a thin diaper lining sheet made from plant-based fibers. Once soiled, simply pick up the liner with the mess, shake the solids off into the toilet and dispose of the liner in the garbage. Do not flush.",
  },
  {
    question: "How do I remove poo from cloth diapers?",
    answer:
      "The fleece diaper lining is designed so that most solids will come off easily. Simply shake off as much solid waste as possible directly into the toilet, remove the diaper insert, and rinse the diaper under running water or using a diaper sprayer. You can also use an old dish brush or silicone spatula to remove any residue. For babies that are exclusively breast-fed you don’t have remove the milky solids, because their poop is water-soluble! For easier clean-up of pasty or solid poo, we recommend using our disposable liners – a thin diaper lining sheet made from plant-based fibers. Once soiled, simply pick up the liner with the mess, shake the solids off into the toilet and dispose of the liner in the garbage. Do not flush.",
  },
  {
    question: "How do I remove poo from cloth diapers?",
    answer:
      "The fleece diaper lining is designed so that most solids will come off easily. Simply shake off as much solid waste as possible directly into the toilet, remove the diaper insert, and rinse the diaper under running water or using a diaper sprayer. You can also use an old dish brush or silicone spatula to remove any residue. For babies that are exclusively breast-fed you don’t have remove the milky solids, because their poop is water-soluble! For easier clean-up of pasty or solid poo, we recommend using our disposable liners – a thin diaper lining sheet made from plant-based fibers. Once soiled, simply pick up the liner with the mess, shake the solids off into the toilet and dispose of the liner in the garbage. Do not flush.",
  },
  // More questions...
]

export default function HowToWash() {
  useTitle("How to Wash" + pageTitle);

  return (
    <div className="bg-primary-light">
      <Helmet>
        <meta
          name="description"
          content="Bumbi"
        />
      </Helmet>
        <section class="overflow-hidden relative">
            <div class="mx-auto max-w-screen-xl grid grid-cols-12 bg-primary-700 dark:bg-gray-900">
                <div class="px-24 col-span-12 md:col-span-6 text-center flex flex-col justify-center">
                    <h2 class="mb-4 text-xl font-extrabold tracking-tight leading-none text-primary-light md:text-2xl lg:text-3xl dark:text-white">How to wash</h2>
                    <p class="mb-6 font-light text-primary-light text-sm lg:mb-8 md:text-base lg:text-lg">Bumbi brings you the simplest tips to make washing your cloth diapers as easy as imaginable!.</p>
                </div>
                <div class="col-span-12 w-full md:col-span-6 h-full max-h-72 md:max-h-72 md:block order-first md:order-last">
                    <img class="object-cover w-full h-full" src={ProductImage} alt="How to wash Bumbi"/>
                </div>
            </div>
        </section>
        <section className="text-center py-12 px-6 max-w-xl mx-auto">
            <h3 className="font-bold text-xl">
                Take the stress out of cloth diaper care
            </h3>
            <p className="mt-4">
                Whether you are a cloth diapering expert or a completely new starter, advice on how to wash your cloth diaper can be overwhelming.
            </p>
            <p className="mt-4">
                We have condensed our decades of expertise and tips from successful cloth diapering parents to give you everything you need to get washing!
            </p>
        </section>
        <section className="py-12 px-6 max-w-screen-xl mx-auto">
            <h3 className="font-bold text-xl text-center">
                Simple steps for washing cloth diapers
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-4">
                <div class="flex flex-col items-center border border-gray-200 rounded-lg md:flex-row md:max-w-xl dark:border-gray-700 dark:bg-gray-800 gap-4">
                    <img class="pl-2 object-contain rounded-t-lg h-24 w-24 md:rounded-none md:rounded-s-lg" src={CreatureImage} alt=""/>
                    <div class="flex flex-col justify-between py-4 pr-4 pl-2 leading-normal">
                        <h5 class="mb-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white">1. Remove solids</h5>
                        <p class="mb-3 font-light text-gray-700 dark:text-gray-400">Shake any solids directly into the toilet, or use a diaper sprayer to rinse off solid residue. No need to pre-rinse diapers that are only wet with urine.</p>
                    </div>
                </div>
                <div class="flex flex-col items-center border border-gray-200 rounded-lg md:flex-row md:max-w-xl dark:border-gray-700 dark:bg-gray-800 gap-4">
                    <img class="pl-2 object-contain rounded-t-lg h-24 w-24 md:rounded-none md:rounded-s-lg" src={CreatureImage} alt=""/>
                    <div class="flex flex-col justify-between py-4 pr-4 pl-2 leading-normal">
                        <h5 class="mb-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white">1. Remove solids</h5>
                        <p class="mb-3 font-light text-gray-700 dark:text-gray-400">Shake any solids directly into the toilet, or use a diaper sprayer to rinse off solid residue. No need to pre-rinse diapers that are only wet with urine.</p>
                    </div>
                </div>
                <div class="flex flex-col items-center border border-gray-200 rounded-lg md:flex-row md:max-w-xl dark:border-gray-700 dark:bg-gray-800 gap-4">
                    <img class="pl-2 object-contain rounded-t-lg h-24 w-24 md:rounded-none md:rounded-s-lg" src={CreatureImage} alt=""/>
                    <div class="flex flex-col justify-between py-4 pr-4 pl-2 leading-normal">
                        <h5 class="mb-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white">2. Store or pre-treat</h5>
                        <p class="mb-3 font-light text-gray-700 dark:text-gray-400">Shake any solids directly into the toilet, or use a diaper sprayer to rinse off solid residue. No need to pre-rinse diapers that are only wet with urine.</p>
                    </div>
                </div>
                <div class="flex flex-col items-center border border-gray-200 rounded-lg md:flex-row md:max-w-xl dark:border-gray-700 dark:bg-gray-800 gap-4">
                    <img class="pl-2 object-contain rounded-t-lg h-24 w-24 md:rounded-none md:rounded-s-lg" src={CreatureImage} alt=""/>
                    <div class="flex flex-col justify-between py-4 pr-4 pl-2 leading-normal">
                        <h5 class="mb-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white">4. Dry</h5>
                        <p class="mb-3 font-light text-gray-700 dark:text-gray-400">Shake any solids directly into the toilet, or use a diaper sprayer to rinse off solid residue. No need to pre-rinse diapers that are only wet with urine.</p>
                    </div>
                </div>
            </div>
        </section>

        <FAQ faqs={faqs} title="Still got questions on how to wash?" />
    </div>
  );
}
