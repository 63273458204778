import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Dialog, Popover, Tab, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  Bars3Icon,
  ShoppingBagIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import { useDispatch, useSelector } from "react-redux";
import { autoLogin } from "../redux/actions/authAction";
import { getUserCart } from "../redux/actions/productsAction";
import { getProduct } from "../redux/actions/productsAction";

import ClientLogo from "../images/bumbi-logo.webp";

const currencies = ["CAD", "USD", "AUD", "EUR", "GBP"];

const navigation = [
  { name: "Home", href: "/" },
  { name: "About Us", href: "/about" },
  { name: "Contact Us", href: "/contact" },
  // { name: "Past Projects", href: "/past-projects" },
  // { name: "Partners", href: "/partners" },
  // { name: "Awards", href: "/awards" },
  { name: "Products", href: "/products" },
  // { name: "How to wash", href: "/how-to-wash" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavbarSimple() {
  const navigate = useNavigate();
  const products = useSelector((state) => state.products.cart_list);
  const user_token = useSelector((state) => state.auth.token);
  const hasToken = useSelector((state) => state.auth.hasToken);
  const dispatch = useDispatch();

  // const [hasToken, setHasToken] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const [inputValue, setInputValue] = useState("");
  const typingTimer = useRef(null);
  const doneTypingInterval = 1000;

  const handleKeyUp = () => {
    clearTimeout(typingTimer.current);
    typingTimer.current = setTimeout(doneTyping, doneTypingInterval);
  };

  const handleKeyDown = () => {
    clearTimeout(typingTimer.current);
  };

  const doneTyping = () => {
    dispatch(getProduct(null, inputValue));
  };

  const { pathname } = window?.location;
  const pathIndicator = (path) => {
    let _default = pathname;
    if (pathname == "/event-list") _default = "/";
    if (path == _default) {
      return "border-purple-500 border-b-2 dark:text-white";
    }
    return "dark:text-dark-400";
  };

  const pathMobile = (path) => {
    console.log("path: ", pathname);
    let _default = pathname;
    if (pathname == "/event-list") _default = "/";
    if (path == _default) {
      return "dark:bg-dark-800 border-primary-500 text-primary-700 dark:text-primary-500";
    }
    return "dark:text-black dark:bg-dark-500 bg-primary-50";
  };

  const logout = async () => {
    await localStorage.clear();
    await dispatch({ type: "LOGOUT" });
    // await setHasToken(false);
    await navigate("/auth/");
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    console.log("helo: ", token);

    if (token) {
      // setHasToken(true);
      dispatch(autoLogin());
    }
  }, []);

  useEffect(() => {
    dispatch(getUserCart());
  }, []);

  useEffect(() => {
    console.log("user token: ", user_token);
    if (user_token) {
      console.log("user token not null ");
      window.location.reload();
    }
  }, [user_token]);
  return (
    <>
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={setMobileMenuOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                <div className="flex px-4 pb-2 pt-5">
                  <button
                    type="button"
                    className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-dark-400"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  <a href="/" className="ml-4">
                    <span className="sr-only">Bumbi</span>
                    <img className="h-8 w-auto" src={ClientLogo} alt="" />
                  </a>
                </div>

                {/* Links */}
                <div className="grid grid-cols-1 gap-x-6 py-4">
                  {navigation.map((category, categoryIdx) => (
                    <a
                      href={category.href}
                      className="p-4 text-primary-dark hover:bg-primary-dark hover:text-primary-light hover:underline"
                    >
                      {category.name}
                    </a>
                  ))}
                </div>

                {/* <div className="space-y-6 border-t border-dark-200 px-4 py-6">
                  {navigation.pages.map((page) => (
                    <div key={page.name} className="flow-root">
                      <a
                        href={page.href}
                        className="-m-2 block p-2 font-medium text-dark-900"
                      >
                        {page.name}
                      </a>
                    </div>
                  ))}
                  <a
                    href="/products"
                    className="-m-2 block p-2 font-medium text-dark-900"
                  >
                    Our Products
                  </a>
                </div> */}

                {/* <div className="space-y-6 border-t border-dark-200 px-4 py-6">
                  <div className="flow-root">
                    {hasToken ? (
                      ""
                    ) : (
                      <a
                        href="/auth/register"
                        className="-m-2 block p-2 text-sm font-medium text-dark-500 hover:text-dark-800 hover:underline"
                      >
                        Buat akun
                      </a>
                    )}
                  </div>
                  <div className="flow-roo flex flex-col gap-4">
                    {hasToken ? (
                      <>
                        <a
                          href="/my-profile"
                          className="text-sm font-medium text-dark-500 hover:text-dark-800 hover:underline"
                        >
                          Profil
                        </a>
                        <a
                          href="/my-orders"
                          className="text-sm font-medium text-dark-500 hover:text-dark-800 hover:underline"
                        >
                          Histori Transaksi
                        </a>
                        <a
                          href="/"
                          className="text-sm font-medium text-dark-500 hover:text-dark-800 hover:underline"
                          onClick={() => logout()}
                        >
                          Logout
                        </a>
                      </>
                    ) : (
                      <a
                        href="/auth/"
                        className="text-sm font-medium text-dark-500 hover:text-dark-800 hover:underline"
                      >
                        Login
                      </a>
                    )}
                  </div>
                </div> */}

                <div className="hidden space-y-6 border-t border-dark-200 px-4 py-6">
                  {/* Currency selector */}
                  <form>
                    <div className="inline-block">
                      <label htmlFor="mobile-currency" className="sr-only">
                        Currency
                      </label>
                      <div className="group relative -ml-2 rounded-md border-transparent focus-within:ring-2 focus-within:ring-white">
                        <select
                          id="mobile-currency"
                          name="currency"
                          className="flex items-center rounded-md border-transparent bg-none py-0.5 pl-2 pr-5 text-sm font-medium text-dark-100 focus:border-transparent focus:outline-none focus:ring-0 group-hover:text-dark-800"
                        >
                          {currencies.map((currency) => (
                            <option key={currency}>{currency}</option>
                          ))}
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                          <ChevronDownIcon
                            className="h-5 w-5 text-dark-500"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <header className="relative z-10">
        <nav aria-label="Top">
          {/* Secondary navigation */}
          <div className="bg-white">
            <div className="border-b border-dark-200">
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                  {/* Logo (lg+) */}
                  <div className="hidden lg:flex lg:items-center">
                    <a href="/">
                      <span className="sr-only">Bumbi</span>
                      <img className="h-8 w-auto" src={ClientLogo} alt="" />
                    </a>
                  </div>

                  <div className="hidden h-full gap-4 pl-8 lg:flex lg:flex-1">
                    {/* Mega menus */}
                    {navigation.map((page) => (
                      <a
                        key={page.name}
                        href={page.href}
                        className="relative z-10 -mb-px flex items-center border-b-2 border-transparent px-4 pt-px text-sm font-medium text-primary-600 transition-colors duration-200 ease-out hover:text-dark-800"
                      >
                        {page.name}
                      </a>
                    ))}
                  </div>

                  {/* Mobile menu and search (lg-) */}
                  <div className="flex items-center lg:hidden">
                    <button
                      type="button"
                      className="-ml-2 rounded-md bg-white p-2 text-dark-500"
                      onClick={() => setMobileMenuOpen(true)}
                    >
                      <span className="sr-only">Open menu</span>
                      <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>

                    {/* Search */}
                    {/* <a
                      href="#"
                      className="ml-2 p-2 text-dark-400 hover:text-dark-500"
                    >
                      <span className="sr-only">Search</span>
                      <MagnifyingGlassIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                    </a> */}
                  </div>

                  {/* Logo (lg-) */}
                  <a href="/" className="ml-2 flex-1 lg:hidden">
                    <span className="sr-only">Bumbi</span>
                    <img src={ClientLogo} alt="" className="h-8 w-auto" />
                  </a>

                  <div className="flex items-center justify-end px-2 md:justify-between">
                    {/* Search Bar */}
                    {/* <div className="hidden w-full text-dark-900 md:block">
                      <input
                        type="text"
                        value={inputValue}
                        placeholder="Cari produk..."
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyUp={handleKeyUp}
                        onKeyDown={handleKeyDown}
                        className="w-full border-dark-100 py-1 focus:border-primary-500 focus:accent-primary-500 focus:ring-primary-500"
                      />
                    </div> */}

                    <div className="flex items-center lg:ml-8">
                      {/* <div className="flex space-x-8">
                        <div className="flex cursor-pointer">
                          <div
                            className="p-2 text-dark-500 hover:text-dark-300"
                            onClick={() => {
                              hasToken
                                ? navigate("/my-profile")
                                : navigate("/login");
                            }}
                          >
                            <span className="sr-only">Account</span>
                            <UserIcon className="h-6 w-6" aria-hidden="true" />
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="flow-root">
                        <button
                          className="group flex items-center p-2"
                          onClick={() => navigate("/cart")}
                        >
                          <ShoppingBagIcon
                            className="h-6 w-6 flex-shrink-0 text-dark-500 group-hover:text-dark-400"
                            aria-hidden="true"
                          />
                          <span className="sr-only ml-2 text-sm font-medium text-dark-100 group-hover:text-dark-300">
                            {products && products.length}
                          </span>
                          <span className="sr-only">
                            items in cart, view bag
                          </span>
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}
