import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import WaveImage from "../images/layered-waves-haikei.svg";
import ClientLogo from "../images/bumbi-logo-2.webp";
import InstagramLogo from "../images/instagram-2016-5.svg";
import ShopeeLogo from "../images/shopee-logo.png";
import TokpedLogo from "../images/tokopedia-logo.png";

const navigation = {
  products: [{ name: "Products", href: "/products" }],
  // support: [
  //   // { name: "Measurement Guide", href: "/measurement-guide" },
  //   { name: "FAQ", href: "/faq" },
  //   { name: "Kebijakan Retur", href: "/return-policy" },
  // ],
  company: [
    { name: "About Us", href: "/about" },
    { name: "Contact Us", href: "/contact" },
  ],
  // legal: [
  //   { name: "Privacy", href: "/privacy-policy" },
  //   { name: "Terms", href: "/tnc" },
  // ],
  social: [
    {
      name: "Instagram",
      href: "https://www.instagram.com/bumbi_id/",
      icon: (props) => (
        // <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        //   <path
        //     fillRule="evenodd"
        //     d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
        //     clipRule="evenodd"
        //   />
        // </svg>
        <img
          className="h-6 w-6"
          src={InstagramLogo}
          alt="logo instagram menuju shop Bumbi"
        />
      ),
    },
    {
      name: "Shopee",
      href: "https://shp.ee/t69c58h",
      icon: (props) => (
        <img
          className="h-6 w-6"
          src={ShopeeLogo}
          alt="logo shopee menuju shop Bumbi"
        />
      ),
    },
    {
      name: "Tokopedia",
      href: "#",
      icon: (props) => (
        <img
          className="h-6 w-6"
          src={TokpedLogo}
          alt="logo tokopedia menuju shop Bumbi"
        />
      ),
    },
  ],
};

export default function FooterSimple() {
  const [cat, setCat] = useState(null);
  const categories = useSelector((state) => state.products.category_list);

  useEffect(() => {
    let featured = [
      { name: "Products", href: "/products" },
      { name: "How to Wash", href: "/how-to-wash" },
    ];

    for (let i in categories) {
      featured.push({
        name: categories[i].name,
        href: `/collections?category=${categories[i].id}`,
      });
    }

    setCat(featured);
  }, [categories]);

  return (
    <footer className="bg-primary-light">
      <img
        src={WaveImage}
        alt="Wave"
        className="h-40 w-full object-cover object-bottom lg:h-56 xl:h-96"
      />
      <div className="bg-primary-dark py-2">
        <div className="mx-auto max-w-screen-xl text-center">
          <a
            href="/"
            className="flex items-center justify-center text-2xl font-semibold text-primary-light dark:text-white"
          >
            <img
              src={ClientLogo}
              alt="Bumbi logo"
              className="h-8 w-auto max-w-[10em] object-contain"
            />
          </a>
          {/* <p className="mt-2 text-xs font-light leading-8 text-accent-dark md:text-sm">
            dari <span className="text-primary-light">Bunda</span> untuk{" "}
            <span className="text-primary-light">Bumi</span> dan{" "}
            <span className="text-primary-light">Bayi</span>
          </p> */}
          <ul className="my-4 flex flex-wrap items-center justify-center gap-4 font-medium text-primary-light dark:text-white">
            <li>
              <a href="/" className="hover:underline">
                Home
              </a>
            </li>
            <li>
              <a href="/about" className="hover:underline">
                About Us
              </a>
            </li>
            <li>
              <a href="/contact" className="hover:underline">
                Contact Us
              </a>
            </li>
            <li>
              <a href="/products" className="hover:underline">
                Products
              </a>
            </li>
          </ul>
          <span className="text-xs font-light text-primary-light sm:text-center">
            Website by{" "}
            <a href="https://erista.id" target="_blank" rel="noreferrer">
              PT Erista Dwatiga Teknologi
            </a>{" "}
            / &copy; 2024 Bumbi. All rights reserved.
          </span>
        </div>
      </div>
    </footer>
  );
}
