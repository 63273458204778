import LeafImage from "../../images/leaf.png"
import SavingsImage from "../../images/savings.png"
import HeartImage from "../../images/heart.png"

export default function HomeImpact() {
    return (
        <section class="relative isolate bg-accent-light dark:bg-gray-900 antialiased">
            <div
            className="absolute inset-0 -z-10 h-full w-full object-cover bg-accent-light pattern-vertical-lines pattern-accent-dark pattern-bg-accent-light pattern-opacity-10 pattern-size-8"
            />
            <div class="max-w-screen-xl px-4 py-8 mx-auto lg:px-6 sm:py-16 lg:py-24">
                <div class="max-w-3xl mx-auto text-center">
                    <h2 class="text-4xl font-extrabold leading-tight tracking-tight text-primary-700 sm:text-5xl dark:text-white">
                        Our Impact!
                    </h2>
                </div>

                <div className="divide-y divide-primary-dark/30">
                    <div>
                        <div class="p-4 mt-8 flex gap-8 items-center justify-center">
                            <img src={LeafImage} alt="Bumbi green" className="h-16 w-16" />
                            <p className="text-2xl text-primary-dark font-semibold">Save <span className="text-accent-500 text-3xl">2,000 pcs</span> of single-use diaper waste per baby annually</p>
                        </div>

                        <div class="p-4 space-y-4 md:space-y-2">
                            <p className="text-2xl text-primary-dark font-semibold text-center">Served over <span className="text-accent-500 text-3xl">4,000</span> babies</p>
                            <p className="text-2xl text-primary-dark font-semibold text-center">Eliminated <span className="text-accent-500 text-3xl">6 million</span> single-use diapers</p>
                            <p className="text-2xl text-primary-dark font-semibold text-center">Prevented <span className="text-accent-500 text-3xl">1,350 tons</span> of plastic waste</p>
                            <p className="text-2xl text-primary-dark font-semibold text-center">Avoided <span className="text-accent-500 text-3xl">6,000 tons</span> of carbon emissions</p>
                        </div>
                    </div>

                    <div class="mt-4 py-12 flex gap-8 items-center justify-center">
                        <img src={SavingsImage} alt="Bumbi green" className="h-20 w-20" />
                        <div className="space-y-4 md:space-y-2">
                            <p className="text-2xl text-primary-dark font-semibold"><span className="text-accent-500 text-3xl">6 Bumbi pcs</span> can save a family</p>
                            <p className="text-2xl text-primary-dark font-semibold"><span className="text-accent-500 text-3xl">50% - 75%</span> in expenses or</p>
                            <p className="text-2xl text-primary-dark font-semibold"><span className="text-accent-500 text-3xl">3 million - 4.5 million</span> /baby annually</p>
                        </div>
                    </div>

                    <div class="mt-4 py-12 px-6 flex flex-col md:flex-row gap-8 items-center justify-center">
                        <img src={HeartImage} alt="Bumbi green" className="h-16 w-16" />
                        <div class="space-y-8 md:grid md:grid-cols-2 md:gap-12 md:space-y-0">
                            <p className="text-2xl text-primary-dark font-semibold">more than <span className="text-accent-500 text-3xl">80%</span> reduction in baby skin rash</p>
                            <p className="text-2xl text-primary-dark font-semibold">more than <span className="text-accent-500 text-3xl">&gt;90%</span> reduction in baby urinal infection</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}