import { Helmet } from "react-helmet-async";
import { useTitle } from "../helper/functions";
import { pageTitle } from "../helper/globals";
import ProductImage from "../images/company/IMG_6243.webp"

export default function Awards() {
  useTitle("About Us" + pageTitle);

  return (
    <div className="bg-primary-light">
      <Helmet>
        <meta
          name="description"
          content="Bumbi"
        />
      </Helmet>
      <section class="bg-primary-700 dark:bg-gray-900">
        <div class="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-12">
            <div class="max-w-2xl mx-auto">
                <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-primary-100 md:text-5xl lg:text-6xl dark:text-white">Our Milestones</h1>
                <p class="mb-8 text-lg font-normal text-primary-300 lg:mb-12 lg:text-xl dark:text-gray-400">We have made the earth more comfortable to live in just <span class="font-medium text-accent-dark dark:text-white">2 years</span> </p>
            </div>
            <div class="grid gap-6 lg:gap-8 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5">
                <div class="p-4 text-primary-light bg-primary-dark rounded-lg dark:bg-primary-900 dark:text-primary-300">
                    <h2 class="text-3xl font-extrabold leading-tight">$55,000</h2>
                    <span class="text-primary-light dark:text-primary-400">ESG Investment 2021</span>
                </div>
                <div class="p-4 text-primary-light bg-primary-dark rounded-lg dark:bg-primary-900 dark:text-primary-300">
                    <h2 class="text-3xl font-extrabold leading-tight">$94,000</h2>
                    <span class="text-primary-light dark:text-primary-400">ESG Investment 2022</span>
                </div>
                <div class="p-4 text-primary-light bg-primary-dark rounded-lg dark:bg-primary-900 dark:text-primary-300">
                    <h2 class="text-3xl font-extrabold leading-tight">$120,000</h2>
                    <span class="text-primary-light dark:text-primary-400">ESG Investment 2023</span>
                </div>
                <div class="p-4 text-primary-light bg-primary-dark rounded-lg dark:bg-primary-900 dark:text-primary-300">
                    <h2 class="text-3xl font-extrabold leading-tight">+100</h2>
                    <span class="text-primary-light dark:text-primary-400">Media Coverages</span>
                </div>
                <div class="p-4 text-primary-light bg-primary-dark rounded-lg dark:text-primary-300 dark:bg-primary-900">
                    <h2 class="text-3xl font-extrabold leading-tight">32</h2>
                    <span class="text-primary-light dark:text-primary-400">Retailers</span>
                </div>
                <div class="p-4 text-primary-light bg-primary-dark rounded-lg dark:text-primary-300 dark:bg-primary-900">
                    <h2 class="text-3xl font-extrabold leading-tight">8</h2>
                    <span class="text-primary-light dark:text-primary-400">Baby Stores</span>
                </div>
                <div class="p-4 text-primary-light bg-primary-dark rounded-lg dark:text-primary-300 dark:bg-primary-900">
                    <h2 class="text-3xl font-extrabold leading-tight">7</h2>
                    <span class="text-primary-light dark:text-primary-400">Merchants</span>
                </div>
                <div class="p-4 text-primary-light bg-primary-dark rounded-lg dark:text-primary-300 dark:bg-primary-900">
                    <h2 class="text-3xl font-extrabold leading-tight">1</h2>
                    <span class="text-primary-light dark:text-primary-400">Hospital</span>
                </div>
            </div>
        </div>
      </section>

      <section class="bg-primary-light dark:bg-gray-900">
        <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            <div class="mx-auto max-w-screen-sm text-center mb-8 lg:mb-16">
                <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Our Awards</h2>
                <p class="font-light text-gray-500 lg:mb-16 sm:text-xl dark:text-gray-400">Explore the whole collection of open-source web components and elements built with the utility classes from Tailwind</p>
            </div> 
            <div class="grid gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                <div class="bg-white rounded-lg border border-gray-200 shadow-sm dark:bg-gray-800 dark:border-gray-700">
                    <a href="#">
                        <img class="p-4 w-full rounded-lg" src={ProductImage} alt="Jese Leos"/>
                    </a>
                    <div class="px-5 pb-5">
                        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                            <a href="#">National ESG Start Up Champs</a>
                        </h3>
                        <span class="text-gray-500">KOICA</span>
                        <p class="mt-3 mb-4 font-light text-gray-500 dark:text-gray-400">Jese drives the technical strategy of the themesberg platform and brand.</p>
                    </div>
                </div>  
                <div class="bg-white rounded-lg border border-gray-200 shadow-sm dark:bg-gray-800 dark:border-gray-700">
                    <a href="#">
                        <img class="p-4 w-full rounded-lg" src={ProductImage} alt="Bonnie Green"/>
                    </a>
                    <div class="px-5 pb-5">
                        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                            <a href="#">International BE Innovative Contest</a>
                        </h3>
                        <span class="text-gray-500">GIZ</span>
                        <p class="mt-3 mb-4 font-light text-gray-500 dark:text-gray-400">Bonnie drives the technical strategy of the themesberg platform and brand.</p>
                    </div>
                </div> 
                <div class="bg-white rounded-lg border border-gray-200 shadow-sm dark:bg-gray-800 dark:border-gray-700">
                    <a href="#">
                        <img class="p-4 w-full rounded-lg" src={ProductImage} alt="Michael Gough"/>
                    </a>
                    <div class="px-5 pb-5">
                        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                            <a href="#">12 Start-ups 2023</a>
                        </h3>
                        <span class="text-gray-500">UNDP and ADB</span>
                        <p class="mt-3 mb-4 font-light text-gray-500 dark:text-gray-400">Michael drives the technical strategy of the themesberg platform and brand.</p>
                    </div>
                </div> 
                <div class="bg-white rounded-lg border border-gray-200 shadow-sm dark:bg-gray-800 dark:border-gray-700">
                    <a href="#">
                        <img class="p-4 w-full rounded-lg" src={ProductImage} alt="Roberta Casas"/>
                    </a>
                    <div class="px-5 pb-5">
                        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                            <a href="#">G20 speaker 2022</a>
                        </h3>
                        <span class="text-gray-500">DFAT</span>
                        <p class="mt-3 mb-4 font-light text-gray-500 dark:text-gray-400">Roberta drives the technical strategy of the themesberg platform and brand.</p>
                    </div>
                </div> 
                <div class="bg-white rounded-lg border border-gray-200 shadow-sm dark:bg-gray-800 dark:border-gray-700">
                    <a href="#">
                        <img class="p-4 w-full rounded-lg" src={ProductImage} alt="Neil Sims"/>
                    </a>
                    <div class="px-5 pb-5">
                        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                            <a href="#">TEDx Speaker 2022</a>
                        </h3>
                        <span class="text-gray-500">TEDx</span>
                        <p class="mt-3 mb-4 font-light text-gray-500 dark:text-gray-400">Neil Sims drives the technical strategy of the themesberg platform and brand.</p>
                    </div>
                </div>  
                <div class="bg-white rounded-lg border border-gray-200 shadow-sm dark:bg-gray-800 dark:border-gray-700">
                    <a href="#">
                        <img class="p-4 w-full rounded-lg" src={ProductImage} alt="Joseph Mcfall"/>
                    </a>
                    <div class="px-5 pb-5">
                        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                            <a href="#">Film production</a>
                        </h3>
                        <span class="text-gray-500">ABC 2023</span>
                        <p class="mt-3 mb-4 font-light text-gray-500 dark:text-gray-400">Joseph drives the technical strategy of the themesberg platform and brand.</p>
                    </div>
                </div> 
                <div class="bg-white rounded-lg border border-gray-200 shadow-sm dark:bg-gray-800 dark:border-gray-700">
                    <a href="#">
                        <img class="p-4 w-full rounded-lg" src={ProductImage} alt="Thomas Lean"/>
                    </a>
                    <div class="px-5 pb-5">
                        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                            <a href="#">Multi-Party Collaborations</a>
                        </h3>
                        <span class="text-gray-500">The British Ambassador and East Java Governor signed</span>
                        <p class="mt-3 mb-4 font-light text-gray-500 dark:text-gray-400">Thomas drives the technical strategy of the themesberg platform and brand.</p>
                    </div>
                </div>
            </div>  
        </div>
    </section>
    </div>
  );
}
