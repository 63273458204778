import FeatureImage from "../../images/company/IMG_6243.webp"
import ProductImage from "../../images/company/bumbi-product.png"

export default function HomeOffer() {
    return (
        <section class="bg-primary-dark dark:bg-gray-900">
            <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 xl:gap-16 sm:py-16 lg:px-6 ">
                <img class="mb-4 w-full lg:mb-0 rounded-lg" src={ProductImage} alt="Bumbi product"/>
                <div class="text-primary-light dark:text-gray-400 sm:text-lg">
                    <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-primary-light dark:text-white">What We Offer</h2>
                    <p class="mb-8 font-light lg:text-xl"><span className="font-semibold uppercase text-accent-dark">Reduce and Reuse</span>. Switching from Single Used Diapers Usage to Reusable Diapers.</p>
                    <div class="py-8 mb-6 border-t border-b border-gray-200 dark:border-gray-700">
                        <div class="flex">
                            <div>
                                <h3 class="mb-2 text-xl font-medium text-primary-light dark:text-white">Made of Cotton</h3>
                                {/* <p class="mb-2 font-light text-gray-500 dark:text-gray-400">Host code that you don't want to share with the world in private GitHub repos only accessible to you and people you share them with.</p> */}
                            </div>
                        </div>
                        <div class="flex pt-2">
                            <div>
                                <h3 class="mb-2 text-xl font-medium text-primary-light dark:text-white">Quick-Dry Liner</h3>
                                {/* <p class="mb-2 font-light text-gray-500 dark:text-gray-400">See the packages your project depends on, the repositories that depend on them, and any vulnerabilities detected.</p> */}
                            </div>
                        </div>
                        <div class="flex pt-2">
                            <div>
                                <h3 class="mb-2 text-xl font-medium text-primary-light dark:text-white">Easy to Wash and Care</h3>
                                {/* <p class="mb-2 font-light text-gray-500 dark:text-gray-400">Find vulnerabilities in custom code using static analysis. Prevent new vulnerabilities from being introduced by scanning every pull request.</p> */}
                            </div>
                        </div>
                        <div class="flex pt-2">
                            <div>
                                <h3 class="mb-2 text-xl font-medium text-primary-light dark:text-white">Available in All Sizes</h3>
                                {/* <p class="mb-2 font-light text-gray-500 dark:text-gray-400">Find vulnerabilities in custom code using static analysis. Prevent new vulnerabilities from being introduced by scanning every pull request.</p> */}
                            </div>
                        </div>
                        <div class="flex pt-2">
                            <div>
                                <h3 class="mb-2 text-xl font-medium text-primary-light dark:text-white">Locally Made by Indonesian Women and Individual with Disabilities</h3>
                                {/* <p class="mb-2 font-light text-gray-500 dark:text-gray-400">Find vulnerabilities in custom code using static analysis. Prevent new vulnerabilities from being introduced by scanning every pull request.</p> */}
                            </div>
                        </div>
                    </div>
                    {/* <p class="text-sm">Deliver great service experiences fast - without the complexity of traditional ITSM solutions.Accelerate critical development work, eliminate toil, and deploy changes with ease.</p> */}
                </div>
            </div>
        </section>
    )
}