export default function HomeMilestone() {
  return (
    <section class="bg-primary-700 dark:bg-gray-900">
      <div class="mx-auto max-w-screen-xl px-4 py-8 text-center lg:px-12 lg:py-16">
        <div class="mx-auto max-w-2xl">
          <h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-primary-100 dark:text-white md:text-5xl lg:text-6xl">
            Our Milestones
          </h1>
          <p class="mb-8 text-lg font-normal text-primary-300 dark:text-gray-400 lg:mb-12 lg:text-xl">
            We have made the earth more comfortable to live in just{" "}
            <span class="font-medium text-accent-dark dark:text-white">
              3 years
            </span>{" "}
          </p>
        </div>
        <div class="grid gap-6 sm:grid-cols-2 md:grid-cols-3 lg:gap-8 xl:grid-cols-5">
          <div class="rounded-lg bg-primary-dark p-4 text-primary-light dark:bg-primary-900 dark:text-primary-300">
            <h2 class="text-3xl font-extrabold leading-tight">$869,000</h2>
            <span class="text-primary-light dark:text-primary-400">
              ESG Investment 2021 - 2024
            </span>
          </div>
          <div class="rounded-lg bg-primary-dark p-4 text-primary-light dark:bg-primary-900 dark:text-primary-300">
            <h2 class="text-3xl font-extrabold leading-tight">+100</h2>
            <span class="text-primary-light dark:text-primary-400">
              Media Coverages
            </span>
          </div>
          <div class="rounded-lg bg-primary-dark p-4 text-primary-light dark:bg-primary-900 dark:text-primary-300">
            <h2 class="text-3xl font-extrabold leading-tight">32</h2>
            <span class="text-primary-light dark:text-primary-400">
              Retailers
            </span>
          </div>
          <div class="rounded-lg bg-primary-dark p-4 text-primary-light dark:bg-primary-900 dark:text-primary-300">
            <h2 class="text-3xl font-extrabold leading-tight">8</h2>
            <span class="text-primary-light dark:text-primary-400">
              Baby Stores
            </span>
          </div>
          <div class="rounded-lg bg-primary-dark p-4 text-primary-light dark:bg-primary-900 dark:text-primary-300">
            <h2 class="text-3xl font-extrabold leading-tight">7</h2>
            <span class="text-primary-light dark:text-primary-400">
              Merchants
            </span>
          </div>
          <div class="rounded-lg bg-primary-dark p-4 text-primary-light dark:bg-primary-900 dark:text-primary-300">
            <h2 class="text-3xl font-extrabold leading-tight">1</h2>
            <span class="text-primary-light dark:text-primary-400">
              Hospital
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}
