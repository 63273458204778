import SDG1 from "../../images/company/sdg/E_WEB_01.png";
import SDG2 from "../../images/company/sdg/E_WEB_05.png";
import SDG3 from "../../images/company/sdg/E_WEB_08.png";
import SDG4 from "../../images/company/sdg/E_WEB_13.png";
import SDG5 from "../../images/company/sdg/E_WEB_14.png";

export default function HomeSDG() {
  return (
    <section class="bg-primary-light dark:bg-gray-900">
      <div class="mx-auto max-w-screen-xl px-4 py-8 sm:py-16 lg:px-6">
        <div class="mx-auto mb-8 max-w-screen-md text-center lg:mb-16">
          <h2 class="mb-4 text-4xl font-extrabold tracking-tight text-primary-700 dark:text-white">
            Advancing SDGs: Bumbi's impact in 5 years
          </h2>
          {/* <p class="font-light text-primary-700 dark:text-primary-light sm:text-xl">
            Here at Flowbite we focus on markets where technology, innovation,
            and capital can unlock long-term value and drive economic growth.
          </p> */}
        </div>
        <div class="grid grid-cols-2 gap-4 md:gap-8 md:space-y-0 lg:grid-cols-3 xl:grid-cols-4 xl:gap-8">
          <div class="p-2 md:p-6">
            <div class="lg:w40 mb-4 flex w-full items-center justify-center">
              <img src={SDG1} alt="Bumbi SDG 1" />
            </div>
            <h3 class="mb-2 text-xl font-bold text-primary-700 dark:text-white">
              No Poverty
            </h3>
            <p class="font-light text-primary-700 dark:text-primary-light">
              Save{" "}
              <span className="font-semibold">$193 per baby each year</span>, or
              up to{" "}
              <span className="font-semibold">
                $54 million in family savings
              </span>{" "}
              from switching to reusable diapers
            </p>
          </div>
          <div class="p-2 md:p-6">
            <div class="lg:w40 mb-4 flex w-full items-center justify-center">
              <img src={SDG2} alt="Bumbi SDG 5" />
            </div>
            <h3 class="mb-2 text-xl font-bold text-primary-700 dark:text-white">
              Gender Equality
            </h3>
            <p class="font-light text-primary-700 dark:text-primary-light">
              Empower{" "}
              <span className="font-semibold">&gt;600 Women and Disabled</span>{" "}
              for being seamstresses,{" "}
              <span className="font-semibold">&gt;300 health workers</span> for
              being resellers
            </p>
          </div>
          <div class="p-2 md:p-6">
            <div class="lg:w40 mb-4 flex w-full items-center justify-center">
              <img src={SDG3} alt="Bumbi SDG 8" />
            </div>
            <h3 class="mb-2 text-xl font-bold text-primary-700 dark:text-white">
              Decent Work and Economic Growth
            </h3>
            <p class="font-light text-primary-700 dark:text-primary-light">
              Creating{" "}
              <span className="font-semibold">
                Total $57 million economoic benefits
              </span>{" "}
              to Indonesia
            </p>
          </div>
          <div class="p-2 md:p-6">
            <div class="lg:w40 mb-4 flex w-full items-center justify-center">
              <img src={SDG4} alt="Bumbi SDG 13" />
            </div>
            <h3 class="mb-2 text-xl font-bold text-primary-700 dark:text-white">
              Climate Action
            </h3>
            <p class="font-light text-primary-700 dark:text-primary-light">
              Avoiding{" "}
              <span className="font-semibold">
                350,000 tonnes CO<sub>2</sub>
              </span>{" "}
              Emission
            </p>
          </div>
          <div class="p-2 md:p-6">
            <div class="lg:w40 mb-4 flex w-full items-center justify-center">
              <img src={SDG5} alt="Bumbi SDG 14" />
            </div>
            <h3 class="mb-2 text-xl font-bold text-primary-700 dark:text-white">
              Life Below Water
            </h3>
            <p class="font-light text-primary-700 dark:text-primary-light">
              Avoiding{" "}
              <span className="font-semibold">
                &gt;345 millions Single-Used Diapers or 78,000 tonnes plastic
                waste
              </span>{" "}
              thrown into Rivers (main water source) and Oceans
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
