import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useTitle } from "../helper/functions";
import { pageTitle } from "../helper/globals";
import {
  getCarousel,
  getCategory,
  getProduct,
} from "../redux/actions/productsAction";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate } from "react-router-dom";
import ClientLogo from "../images/bumbi-logo.webp"
import EarthImage from "../images/company/earth.png";
import Creature1 from "../images/creature-1.webp";
import Creature2 from "../images/creature-2.webp";
import Creature3 from "../images/creature-3.webp";
import Creature4 from "../images/creature-4.webp";
import Creature5 from "../images/creature-5.webp";
import Features from "../components/marketing/features";
import HomeOffer from "../components/marketing/homeOffer";
import HomeImpact from "../components/marketing/homeImpact";
import HomeSDG from "../components/marketing/homeSDG";
import HomeMilestone from "../components/marketing/homeMilestone";
import LazyImage from "../components/lazyImage";

export default function Home() {
  const navigate = useNavigate;
  const dispatch = useDispatch();
  const carousel = useSelector((state) => state.products.carousel);
  const [carousels, setCarousels] = useState([]);

  useTitle("Home" + pageTitle);

  useTitle("Bumbi");

  const carouselButton = () => {
    if (carousels && carousels.length > 0) {
      carousels.map((item, index) => {
        return (
          <button
            key={index}
            type="button"
            className="h-3 w-3 rounded-full"
            aria-current="true"
            aria-label={`Slide ${index + 1}`}
            data-carousel-slide-to={`${index}`}
          ></button>
        );
      });
    } else {
      return (
        <>
          <button
            type="button"
            className="h-3 w-3 rounded-full"
            aria-current="true"
            aria-label="Slide 1"
            data-carousel-slide-to="0"
          ></button>
          <button
            type="button"
            className="h-3 w-3 rounded-full"
            aria-current="false"
            aria-label="Slide 2"
            data-carousel-slide-to="1"
          ></button>
          <button
            type="button"
            className="h-3 w-3 rounded-full"
            aria-current="false"
            aria-label="Slide 3"
            data-carousel-slide-to="2"
          ></button>
        </>
      );
    }
  };

  useEffect(() => {
    dispatch(getCarousel());
    dispatch(getProduct());
    dispatch(getCategory());
  }, []);

  useEffect(() => {
    setCarousels(carousel);
  }, [carousel?.length > 0]);

  return (
    <div className="relative">
      <Helmet>
        <meta name="description" content="Bumbi description." />
      </Helmet>
      {/* <main>
        <div className="">
          <Carousel autoPlay infiniteLoop>
            {carousels && carousels.length > 0 ? (
              carousels.map((item, index) => (
                <div
                  key={index}
                  onClick={() => window.open(item.custom_url, "_self")}
                  className="relative h-96"
                >
                  <LazyImage
                    className="absolute left-1/2 top-1/2 block h-full w-full -translate-x-1/2 -translate-y-1/2 object-cover"
                    src={`${process.env.REACT_APP_BACKEND_API}/file/${item?.image_link}`}
                    alt=""
                  />
                  <h3 className="absolute bottom-4 left-4 text-xs font-light uppercase">
                    {item?.desc}
                  </h3>
                </div>
              ))
            ) : (
              <>
                <div
                  key={1}
                  onClick={() =>
                    window.open("/collections?category=2", "_self")
                  }
                  className="relative h-96"
                >
                  <LazyImage
                    alt=""
                    className="absolute left-1/2 top-1/2 block h-full w-full -translate-x-1/2 -translate-y-1/2 object-cover"
                    src=""
                  />
                  <h3 className="absolute bottom-4 left-4 text-xs font-light uppercase">
                    Slide 1
                  </h3>
                </div>
              </>
            )}
          </Carousel>
        </div>
      </main> */}
      <section className="bg-accent-light">
        <div className="relative isolate overflow-hidden">
          <div className="pattern-vertical-lines absolute inset-0 -z-10 h-full w-full bg-accent-light object-cover pattern-accent-dark pattern-bg-accent-light pattern-opacity-10 pattern-size-8" />
          <div className="relative mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
            <LazyImage
              src={Creature1}
              alt=""
              className="absolute left-10 top-0 -z-10 h-16 w-16 object-contain lg:left-0 lg:top-20 lg:h-20 lg:w-20"
            />
            <LazyImage
              src={Creature3}
              alt=""
              className="absolute right-20 top-2 -z-10 h-10 w-10 rotate-12 object-contain lg:right-0 lg:top-20 lg:h-16 lg:w-16"
            />
            <LazyImage
              src={Creature2}
              alt=""
              className="absolute right-8 top-36 -z-10 h-10 w-10 rotate-12 object-contain lg:-right-32 lg:top-1/2 lg:h-16 lg:w-16"
            />
            <LazyImage
              src={Creature4}
              alt=""
              className="absolute bottom-36 left-4 -z-10 h-10 w-10 -rotate-12 object-contain lg:-left-32 lg:bottom-40 lg:h-16 lg:w-16"
            />
            <LazyImage
              src={Creature5}
              alt=""
              className="absolute bottom-10 right-1/3 -z-10 h-10 w-10 object-contain lg:h-16 lg:w-16"
            />
            <div className="hidden sm:mb-8 sm:flex sm:justify-center">
              <h1 className="relative rounded-full px-3 py-1 text-xl font-bold leading-6 text-primary-dark ring-1 ring-white/10 hover:ring-white/20">
                "The First Circular Diapers"
                {/* <a href="#" className="font-semibold text-white">
                  <span className="absolute inset-0" aria-hidden="true" />
                  Read more <span aria-hidden="true">&rarr;</span>
                </a> */}
              </h1>
            </div>
            <div className="text-center">
              <div className="flex justify-center">
                <LazyImage
                  src={ClientLogo}
                  alt="Logo Bumbi"
                  className="h-20 md:h-32"
                />
              </div>
              <p className="mt-6 text-2xl font-semibold leading-8 text-accent-dark md:text-4xl">
                dari <span className="text-primary-dark">Bunda</span> untuk{" "}
                <span className="text-primary-dark">Bumi</span> dan{" "}
                <span className="text-primary-dark">Bayi</span>
              </p>
              {/* <div className="mt-10 flex items-center justify-center gap-x-6">
                <a
                  href="#"
                  className="rounded-md bg-primary-dark px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-accent-dark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                >
                  Get started
                </a>
                <a
                  href="#"
                  className="text-sm font-semibold leading-6 text-dark-600 hover:text-accent-dark"
                >
                  Learn more <span aria-hidden="true">→</span>
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <section className="bg-primary-light">
        <div className="relative">
          <div className="relative flex h-80 items-center justify-center overflow-hidden bg-primary-dark md:absolute md:left-0 md:h-full md:w-1/3 lg:w-1/2">
            <LazyImage
              className="h-3/4 w-3/4 object-contain"
              src={EarthImage}
              alt=""
            />
          </div>
          <div className="relative mx-auto max-w-7xl py-24 sm:py-32 lg:px-8 lg:py-40">
            <div className="pl-6 pr-6 md:ml-auto md:w-2/3 md:pl-16 lg:w-1/2 lg:pl-24 lg:pr-0 xl:pl-32">
              <p className="mt-6 text-4xl font-semibold leading-10 text-primary-600">
                We need the Ocean, <br />
                We love the sea. <br />
                But we know <br />
                they’re in trouble, <br />
                which means <br />
                <span className="text-accent-500">We are too.</span>
              </p>
              {/* <div className="mt-8">
                <a
                  href="#"
                  className="inline-flex rounded-md bg-primary-dark px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-white/20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                >
                  Visit the help center
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <Features />
      <HomeOffer />
      <HomeImpact />
      <HomeSDG />
      <HomeMilestone />

      <section className="bg-primary-light">
        <div className="mx-auto max-w-screen-xl px-4 py-8 lg:py-16">
          <div className="mb-8 grid items-center gap-8">
            <div className="col-span-6 text-center sm:mb-6">
              <p className="mx-auto mb-2 max-w-xl font-semibold text-accent-dark dark:text-gray-400 md:text-lg">
                Our Success Story
              </p>
              <h2 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-primary-dark dark:text-white md:text-5xl">
                A Proven Pilot
              </h2>
            </div>
            <div className="col-span-6">
              <iframe
                className="mx-auto h-64 w-full max-w-xl rounded-lg sm:h-96"
                src="https://www.youtube.com/embed/liicaEpOzbo"
                title="Bumbi Pilot Project"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
