export default function NotFound() {
  return (
    <main className="grid min-h-full place-items-center bg-light-50 px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-sm font-semibold uppercase text-accent-dark">404</p>
        <h1 className="mt-4 text-lg font-semibold uppercase tracking-tight text-dark-900 sm:text-2xl">
          Page not found
        </h1>
        <p className="mt-6 text-sm font-light leading-7 text-dark-600">
          Sorry, we couldn’t find the page you’re looking for.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <a
            href="/"
            className="bg-primary-dark rounded-md px-4 py-2 text-xs font-medium uppercase text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
          >
            Go Home
          </a>
          {/* <a href="#" className="text-sm font-semibold text-dark-900">
            Contact support <span aria-hidden="true">&rarr;</span>
          </a> */}
        </div>
      </div>
    </main>
  );
}
