import LeafImage from "../../images/leaf.png"

export default function Features() {
    return (
      <section class="relative isolate bg-accent-light antialiased dark:bg-gray-900">
        <div className="pattern-vertical-lines absolute inset-0 -z-10 h-full w-full bg-accent-light object-cover pattern-accent-dark pattern-bg-accent-light pattern-opacity-10 pattern-size-8" />
        <div class="mx-auto max-w-screen-xl px-4 py-8 sm:py-16 lg:px-6 lg:py-24">
          <div class="mx-auto max-w-3xl text-center">
            <h2 class="text-3xl font-extrabold leading-tight tracking-tight text-primary-700 dark:text-white sm:text-4xl">
              Did you know?
            </h2>
          </div>

          <div class="mt-8 rounded-lg p-4 sm:p-12 lg:mt-16">
            <div class="grid grid-cols-1 gap-8 sm:gap-12 lg:grid-cols-2">
              <div class="flex flex-col items-start gap-4 sm:flex-row sm:gap-5">
                {/* <div class="bg-gray-100 dark:bg-gray-700 rounded-full w-16 h-16 lg:w-24 lg:h-24 flex items-center justify-center shrink-0">
                            <img src={LeafImage} alt="Bumbi green icon" className="p-4" />
                        </div> */}
                <div>
                  <h3 class="text-4xl font-bold text-accent-500 dark:text-white sm:text-5xl">
                    50%
                  </h3>
                  <p class="mt-2 text-base font-semibold text-primary-dark dark:text-gray-400 sm:text-lg">
                    of plastic founds in rivers / main water source are single
                    use diapers.
                  </p>
                </div>
              </div>

              <div class="flex flex-col items-start gap-4 sm:flex-row sm:gap-5">
                {/* <div class="bg-gray-100 dark:bg-gray-700 rounded-full w-16 h-16 lg:w-24 lg:h-24 flex items-center justify-center shrink-0">
                            <img src={LeafImage} alt="Bumbi green icon" className="p-4" />
                        </div> */}
                <div>
                  <h3 class="text-4xl font-bold text-accent-500 dark:text-white sm:text-5xl">
                    5 million tons
                  </h3>
                  <p class="mt-2 text-base font-semibold text-primary-dark dark:text-gray-400 sm:text-lg">
                    Single-use diapers waste produce annually.
                  </p>
                </div>
              </div>

              <div class="flex flex-col items-start gap-4 sm:flex-row sm:gap-5">
                {/* <div class="bg-gray-100 dark:bg-gray-700 rounded-full w-16 h-16 lg:w-24 lg:h-24 flex items-center justify-center shrink-0">
                            
                        </div> */}
                <div>
                  <h3 class="text-4xl font-bold text-accent-500 dark:text-white sm:text-5xl">
                    30%
                  </h3>
                  <p class="mt-2 text-base font-semibold text-primary-dark dark:text-gray-400 sm:text-lg">
                    of baby expenses go towards single used diapers.
                  </p>
                </div>
              </div>

              <div class="flex flex-col items-start gap-4 sm:flex-row sm:gap-5">
                {/* <div class="bg-gray-100 dark:bg-gray-700 rounded-full w-16 h-16 lg:w-24 lg:h-24 flex items-center justify-center shrink-0">
                            
                        </div> */}
                <div>
                  <h3 class="text-4xl font-bold text-accent-500 dark:text-white sm:text-5xl">
                    Pathogens
                  </h3>
                  <p class="mt-2 text-base font-semibold text-primary-dark dark:text-gray-400 sm:text-lg">
                    and Antibiotic resistance are increased and the risk of
                    cholera, hapatitis A, and typhoid.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}