import {
  ChatBubbleBottomCenterIcon,
  EnvelopeIcon,
  MapPinIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import { useTitle } from "../helper/functions";
import { pageTitle } from "../helper/globals";
import { Helmet } from "react-helmet-async";

export default function ContactUs() {
  useTitle("Contact Us" + pageTitle);

  return (
    <div className="bg-primary-light py-24 sm:py-32">
      <Helmet>
        <meta name="description" content="Bumbi description." />
      </Helmet>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl space-y-16 divide-y divide-dark-100 lg:mx-0 lg:max-w-none">
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
            <div>
              <h2 className="text-xl font-semibold uppercase tracking-tight text-primary-700">
                Contact Us
              </h2>
              {/* <p className="mt-2 leading-7 text-primary-700">
                Quam nunc nunc eu sed. Sed rhoncus quis ultricies ac
                pellentesque.
              </p> */}
            </div>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
              <div className="rounded-md p-4">
                {/* <h3 className="text-base font-medium uppercase leading-7 text-primary-700">
                  Support
                </h3> */}
                <dl className="mt-1 space-y-1 text-sm leading-6 text-primary-light">
                  <div className="flex items-center gap-2 text-base font-medium text-primary-700">
                    <dt className="flex-none">
                      <span className="sr-only">Email</span>
                      <EnvelopeIcon
                        className="h-5 w-5 text-primary-700"
                        aria-hidden="true"
                      />
                    </dt>
                    <dd>
                      <a
                        className="text-base font-medium text-primary-700 hover:text-primary-dark hover:underline"
                        href="mailto:contact@bumbi.id"
                        target="_blank"
                        rel="noreferrer"
                      >
                        contact@bumbi.id
                      </a>
                    </dd>
                  </div>
                  <div className="flex items-center gap-2 text-base font-medium text-primary-700">
                    <dt className="flex-none">
                      <span className="sr-only">Telephone</span>
                      <PhoneIcon
                        className="h-5 w-5 text-primary-700"
                        aria-hidden="true"
                      />
                    </dt>
                    <dd>
                      <a
                        className="text-base font-medium text-primary-700 hover:text-primary-dark hover:underline"
                        href="tel:+628113252200"
                      >
                        +628113252200
                      </a>
                    </dd>
                  </div>
                  <div className="flex items-center gap-2 text-base font-medium text-primary-700">
                    <dt className="flex-none">
                      <span className="sr-only">WhatsApp</span>
                      <ChatBubbleBottomCenterIcon
                        className="h-5 w-5 text-primary-700"
                        aria-hidden="true"
                      />
                    </dt>
                    <dd>
                      <a
                        className="text-base font-medium text-primary-700 hover:text-primary-dark hover:underline"
                        href="https://wa.me/628113252200"
                        target="_blank"
                        rel="noreferrer"
                      >
                        WA: +628113252200
                      </a>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 pt-16 lg:grid-cols-3">
            <div>
              <h2 className="text-xl font-semibold uppercase tracking-tight text-primary-700">
                Find Us on Social Media
              </h2>
              {/* <p className="mt-4 leading-7 text-primary-700">
                Consequat sunt cillum cillum elit sint. Qui occaecat nisi in
                ipsum commodo.
              </p> */}
            </div>
            <div className="grid grid-cols-1 gap-2 lg:col-span-2 lg:gap-2">
              <div className="rounded-md px-4">
                {/* <h3 className="text-base font-medium uppercase leading-7 text-primary-700"></h3> */}
                <div className="flex flex-col">
                  <a
                    className="text-base font-medium text-primary-700 hover:text-primary-dark hover:underline"
                    href="https://www.instagram.com/bumbi_id/"
                  >
                    Instagram @bumbi_id
                  </a>
                </div>
              </div>
              <div className="rounded-md px-4">
                {/* <h3 className="text-base font-medium uppercase leading-7 text-primary-700"></h3> */}
                <div className="flex flex-col">
                  <a
                    className="text-base font-medium text-primary-700 hover:text-primary-dark hover:underline"
                    href="https://id.shp.ee/tPU8hFz"
                  >
                    Shopee
                  </a>
                </div>
              </div>
              <div className="rounded-md px-4">
                {/* <h3 className="text-base font-medium uppercase leading-7 text-primary-700"></h3> */}
                <div className="flex flex-col">
                  <a
                    className="text-base font-medium text-primary-700 hover:text-primary-dark hover:underline"
                    href="https://www.tokopedia.com/bumbiid"
                  >
                    Tokopedia
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-primary-700 mt-24">
        <div className="mx-auto max-w-7xl px-6 py-6 sm:py-8 lg:px-8 flex items-center justify-between gap-4">
          <h2 className="text-base font-medium tracking-tight text-primary-light sm:text-xl">Your donation will make a real impact on the lives of others and cleaner oceans.</h2>
          <div className="flex items-center gap-x-6">
            <a href="https://kitabisa.com/bumbi0sampahpopok" target="_blank" rel="noreferrer" className="rounded-md bg-primary-600 whitespace-nowrap px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 transition duration-700">Donate Here</a>
          </div>
        </div>
      </div>
    </div>
  );
}
