import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import AboutUs from "./about";
import ContactUs from "./contact";
import Detail from "./detail";
import FAQ from "./faq";
import OrdersPage from "./order";
import PaymentSuccess from "./payment-success";
import Products from "./products";
import ProfilePage from "./profile";
import ReturnPolicy from "./return-policy";
import TnC from "./tnc";
import PrivacyPolicy from "./privacy-policy";
import MeasurementGuide from "./measurement-guide";
import NotFound from "./notFoundError";
import OrderReceived from "./order-received";
import Home from "./home";
import HowToWash from "./howToWash";
import PastProjects from "./pastProjects";
import Partners from "./partners";
import Awards from "./awards";
import AuthPage from "./Auth";
import NavbarSimple from "../components/navbarSimple";
import FooterSimple from "../components/footerSimple";

export default function Fashion(props) {
  return (
    <div className="font-regular">
      <NavbarSimple />
      <Routes>
        <Route index element={<Home />} />
        <Route path="collections/" element={<Products />} />
        <Route path="collections/detail/:id" element={<Detail />} />
        <Route path="products/" element={<Products />} />
        <Route path="products/detail/:id" element={<Detail />} />
        <Route path="how-to-wash" element={<HowToWash />} />
        <Route path="about/" element={<AboutUs />} />
        <Route path="past-projects/" element={<PastProjects />} />
        <Route path="partners/" element={<Partners />} />
        <Route path="awards/" element={<Awards />} />
        <Route path="contact/" element={<ContactUs />} />
        {/* <Route path="auth/" element={<AuthPage />} />
        <Route path="login/" element={<AuthPage />} />
        <Route path="my-orders/" element={<OrdersPage />} />
        <Route path="payment-success/" element={<PaymentSuccess />} />
        <Route path="order-received/" element={<OrderReceived />} />
        <Route path="my-profile/" element={<ProfilePage />} />
        <Route path="faq/" element={<FAQ />} />
        <Route path="return-policy/" element={<ReturnPolicy />} />
        <Route path="tnc/" element={<TnC />} />
        <Route path="privacy-policy/" element={<PrivacyPolicy />} />
        <Route path="measurement-guide/" element={<MeasurementGuide />} /> */}
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
      <FooterSimple />
    </div>
  );
}
