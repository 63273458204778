import { Dialog, RadioGroup, Transition } from "@headlessui/react";
import {
  ChatBubbleOvalLeftIcon,
  CheckCircleIcon,
  ChevronRightIcon,
  HomeIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MeasurementGuideContent from "../components/info/measurement-guide-content";
import { useTitle } from "../helper/functions";
import { pageTitle } from "../helper/globals";
import { getProductDetail, updateCart } from "../redux/actions/productsAction";
import ClientLogo from "../images/bumbi-logo.webp"
import DefaultButton from "../components/button/button";
import BlueFront from "../images/company/products/blue-front.webp";
import BrownFront from "../images/company/products/brown-front.webp";
import DarkFront from "../images/company/products/dark-front.webp";
import GreyFront from "../images/company/products/grey-front.webp";
import NavyFront from "../images/company/products/navy-front.webp";
import OrangeFront from "../images/company/products/orange-front.webp";
import PinkFront from "../images/company/products/pink-front.webp";
import RedFront from "../images/company/products/red-front.webp";
import YellowFront from "../images/company/products/yellow-front.webp";
import BlueBack from "../images/company/products/blue-back.webp";
import BrownBack from "../images/company/products/brown-back.webp";
import DarkBack from "../images/company/products/dark-back.webp";
import GreyBack from "../images/company/products/grey-back.webp";
import NavyBack from "../images/company/products/navy-back.webp";
import OrangeBack from "../images/company/products/orange-back.webp";
import PinkBack from "../images/company/products/pink-back.webp";
import RedBack from "../images/company/products/red-back.webp";
import YellowBack from "../images/company/products/yellow-back.webp";
import PantsBabyBluefront from "../images/company/products/pants-BabyBlue-front.webp";
import PantsBlackfront from "../images/company/products/pants-Black-front.webp";
import Pantsblueback from "../images/company/products/pants-blue-back.webp";
import PantsDarkKhakifront from "../images/company/products/pants-DarkKhaki-front.webp";
import PantsDarkSlateGrayfront from "../images/company/products/pants-DarkSlateGray-front.webp";
import PantsGrayfront from "../images/company/products/pants-Gray-front.webp";
import PantsIndianRedback from "../images/company/products/pants-IndianRed-back.webp";
import PantsLavenderback from "../images/company/products/pants-Lavender-back.webp";
import PantsLightPinkfront from "../images/company/products/pants-LightPink-front.webp";
import PantsLightSalmonback from "../images/company/products/pants-LightSalmon-back.webp";
import PantsLightSkyBlueback from "../images/company/products/pants-LightSkyBlue-back.webp";
import PantsLightSlateGrayfront from "../images/company/products/pants-LightSlateGray-front.webp";
import PantsLightSteelBlueback from "../images/company/products/pants-LightSteelBlue-back.webp";
import PantsMidnightBlueback from "../images/company/products/pants-MidnightBlue-back.webp";
import Pantsnavyback from "../images/company/products/pants-navy-back.webp";
import PantsOrangeRedback from "../images/company/products/pants-OrangeRed-back.webp";
import Pantspinkback from "../images/company/products/pants-pink-back.webp";
import PantsPlumback from "../images/company/products/pants-Plum-back.webp";
import PantspolkadotBluefront from "../images/company/products/pants-polkadotBlue-front.webp";
import PantspolkadotGreenfront from "../images/company/products/pants-polkadotGreen-front.webp";
import PantsPolkadotPinkback from "../images/company/products/pants-PolkadotPink-back.webp";
import PantsRosyBrownback from "../images/company/products/pants-RosyBrown-back.webp";
import PantsSeaGreenfront from "../images/company/products/pants-SeaGreen-front.webp";
import PantsSilverback from "../images/company/products/pants-Silver-back.webp";
import PantsSkyBluefront from "../images/company/products/pants-SkyBlue-front.webp";
import PantsWhiteColorfulback from "../images/company/products/pants-WhiteColorful-back.webp";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProductDetailPage() {
  const navigate = useNavigate();
  const location = useLocation();
  let params = useParams();
  const dispatch = useDispatch();
  // const product = useSelector((state) => state.products.detail);

  const products = [
    {
      id: 1,
      name: "Pack of 1 Reusable cloth Diapers One Size",
      product_image: BlueFront,
      alt_image: BlueBack,
      product_images: [BlueFront, BlueBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/1",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 2,
      name: "Pack of 1 Reusable cloth Diapers One Size",
      product_image: BrownFront,
      alt_image: BrownBack,
      product_images: [BrownFront, BrownBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/2",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 3,
      name: "Pack of 1 Reusable cloth Diapers One Size",
      product_image: DarkFront,
      alt_image: DarkBack,
      product_images: [DarkFront, DarkBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/3",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 4,
      name: "Pack of 1 Reusable cloth Diapers One Size",
      product_image: GreyFront,
      alt_image: GreyBack,
      product_images: [GreyFront, GreyBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/4",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 5,
      name: "Pack of 1 Reusable cloth Diapers One Size",
      product_image: NavyFront,
      alt_image: NavyBack,
      product_images: [NavyFront, NavyBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/5",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 6,
      name: "Pack of 1 Reusable cloth Diapers One Size",
      product_image: OrangeFront,
      alt_image: OrangeBack,
      product_images: [OrangeFront, OrangeBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/6",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 7,
      name: "Pack of 1 Reusable cloth Diapers One Size",
      product_image: PinkFront,
      alt_image: PinkBack,
      product_images: [PinkFront, PinkBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/7",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 8,
      name: "Pack of 1 Reusable cloth Diapers One Size",
      product_image: RedFront,
      alt_image: RedBack,
      product_images: [RedFront, RedBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/8",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 9,
      name: "Pack of 1 Reusable cloth Diapers One Size",
      product_image: YellowFront,
      alt_image: YellowBack,
      product_images: [YellowFront, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/9",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 11,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsBabyBluefront,
      alt_image: null,
      product_images: [PantsBabyBluefront],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/1",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 12,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsBlackfront,
      alt_image: BrownBack,
      product_images: [PantsBlackfront, BrownBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/2",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 13,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: Pantsblueback,
      alt_image: DarkBack,
      product_images: [Pantsblueback, DarkBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/3",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 14,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsDarkKhakifront,
      alt_image: GreyBack,
      product_images: [PantsDarkKhakifront, GreyBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/4",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 15,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsDarkSlateGrayfront,
      alt_image: NavyBack,
      product_images: [PantsDarkSlateGrayfront, NavyBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/5",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 16,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsGrayfront,
      alt_image: OrangeBack,
      product_images: [PantsGrayfront, OrangeBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/6",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 17,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsIndianRedback,
      alt_image: PinkBack,
      product_images: [PantsIndianRedback, PinkBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/7",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 18,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsLavenderback,
      alt_image: RedBack,
      product_images: [PantsLavenderback, RedBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/8",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 19,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsLightPinkfront,
      alt_image: YellowBack,
      product_images: [PantsLightPinkfront, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/9",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 20,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsLightSalmonback,
      alt_image: YellowBack,
      product_images: [PantsLightSalmonback, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/9",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 21,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsLightSkyBlueback,
      alt_image: YellowBack,
      product_images: [PantsLightSkyBlueback, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/9",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 22,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsLightSlateGrayfront,
      alt_image: YellowBack,
      product_images: [PantsLightSlateGrayfront, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/9",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 23,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsLightSteelBlueback,
      alt_image: YellowBack,
      product_images: [PantsLightSteelBlueback, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/9",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 24,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsMidnightBlueback,
      alt_image: YellowBack,
      product_images: [PantsMidnightBlueback, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/9",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 25,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: Pantsnavyback,
      alt_image: YellowBack,
      product_images: [Pantsnavyback, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/9",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 26,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsOrangeRedback,
      alt_image: YellowBack,
      product_images: [PantsOrangeRedback, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/9",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 27,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: Pantspinkback,
      alt_image: YellowBack,
      product_images: [Pantspinkback, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/9",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 28,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsPlumback,
      alt_image: YellowBack,
      product_images: [PantsPlumback, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/9",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 29,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantspolkadotBluefront,
      alt_image: YellowBack,
      product_images: [PantspolkadotBluefront, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/9",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 30,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantspolkadotGreenfront,
      alt_image: YellowBack,
      product_images: [PantspolkadotGreenfront, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/9",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 31,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsPolkadotPinkback,
      alt_image: YellowBack,
      product_images: [PantsPolkadotPinkback, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/9",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 32,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsRosyBrownback,
      alt_image: YellowBack,
      product_images: [PantsRosyBrownback, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/9",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 33,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsSeaGreenfront,
      alt_image: YellowBack,
      product_images: [PantsSeaGreenfront, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/9",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 34,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsSilverback,
      alt_image: YellowBack,
      product_images: [PantsSilverback, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/9",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 35,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsSkyBluefront,
      alt_image: YellowBack,
      product_images: [PantsSkyBluefront, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/9",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
    {
      id: 36,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsWhiteColorfulback,
      alt_image: YellowBack,
      product_images: [PantsWhiteColorfulback, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 99000,
      max_price: 99000,
      url: "/products/detail/9",
      shopeeURL:
        "https://id.shp.ee/tPU8hFz",
      tokpedURL:
        "https://www.tokopedia.com/bumbiid",
    },
  ];

  const product = {
    id: 1,
    category_id: 1,
    name: "Pack of 1 Reusable cloth Diapers One Size",
    description: `Apakah Ibu Mengalami:
    Anak sering RUAM POPOK karena alergi bahan kimia?😱
    Ingin MENGHEMAT PENGELUARAN pospak dan mencari clodi yang bisa dipakai lama?🤑
    Mau MENGURANGI SAMPAH popok sekali pakai tapi bingung bagaimana caranya?🤔
    
    🤩🤩 POPOK CLODI JENIS POCKET SOLUSINYA 🤩🤩
    
    Popok kami terbuat dari bahan yang lebut untuk pantat bayi dan mengurangi kemungkinan terjadinya ruam popok 🤗
    Kurangi pengeluaran harianmu dengan popok kain super praktis persembahan Common Seas
    
    Popok kain ramah lingkungan pertama yang di produksi oleh para wanita dan penyandang disabilitas di Indonesia.
    Sehat untuk KANTONG, PLANET , dan BAYI anda.
    
    Popok Clodi Common Seas dijamin akan membuat pengalaman popok kain pertama anda menjadi lebih menyenangkan. 😍😍😍.`,
    spesification: "",
    product_image: BlueFront,
    product_images: [BlueFront, BlueBack],
    discount: 99000,
    has_variant: false,
    min_price: 99000,
    max_price: 99000,
    gross_qty: 0,
    gross_price: 0,
    inStock: true,
    stock: 72,
    is_po: false,
    weight: 300,
    indexing: 0,
    isActive: true,
    createdAt: "2023-12-08T00:00:00.000Z",
    updatedAt: "2024-02-05T06:11:03.000Z",
    deletedAt: null,
    ProductColorSizes: [],
    DetailImage: null,
    ColorImages: [],
    ProductCategories: [],
  };

  // const is_loading = useSelector((state) => state.products.detail_loading);
  const is_loading = false;

  const cart_update = useSelector((state) => state.products.cart_loading);
  const [qty, setQty] = useState(1);
  const [sizeVariant, setSize] = useState(null);
  const [selectedVariantStock, setSelectedVariantStock] = useState(1);

  useTitle(product?.name + pageTitle);

  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  // const [selectedColor, setSelectedColor] = useState(product.colors[0])
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const cartUpdate = async () => {
    await dispatch(updateCart(product, selectedVariant, sizeVariant, qty));
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 3000);
  };

  const updateCartData = () => {
    const token = localStorage.getItem("token");

    if (!token) {
      navigate("/auth/", { state: { from: location.pathname } });
    } else {
      console.log("run update cart", selectedVariant);
      console.log("size variant: ", sizeVariant);
      if (product && product.has_variant) {
        if (selectedVariant && sizeVariant) {
          cartUpdate(false);
        } else {
          alert("Please select Color and Size.");
        }
      } else {
        cartUpdate(false);
      }
    }
  };

  const setVariant = (e) => {
    console.log("set variant: ", e);

    setSelectedVariant(e);
    setSize(null);
  };

  const setSizeVariant = (e) => {
    console.log("set variant: ", e);

    setSize(e);
    setSelectedVariantStock(e.stock);
    console.log("selected stock: ", e.stock);
  };

  useEffect(() => {
    // dispatch(getProductDetail(params.id));
    console.log(params.id);
    console.log(
      "Selected Product: ",
      products.find((product) => {
        return product.id == params.id;
      })
    );
    setSelectedProduct(
      products.find((product) => {
        return product.id == params.id;
      })
    );
  }, []);

  // useEffect(() => {
  //   setSelectedVariant(
  //     product?.ProductColorSizes?.length > 0
  //       ? product.ProductColorSizes[0]
  //       : null
  //   );

  //   if (!product?.has_variant) {
  //     setSelectedVariantStock(product?.stock);
  //   }
  // }, [product]);

  useEffect(() => {
    console.log("variant selected: ", selectedVariant);
    console.log("images: ", selectedVariant?.images);
  }, [selectedVariant]);

  function toggleText() {
    console.log("Hello world!");
    var descText = document.getElementById("txtDescription");
    var button = document.getElementById("btnShowMore");

    if (descText.classList.contains("line-clamp-6")) {
      // hide the more text
      descText.classList.remove("line-clamp-6");

      // change text of the button
      button.innerHTML = "Less";
    } else {
      // Hide the more text
      descText.classList.add("line-clamp-6");

      // change text of the button
      button.innerHTML = "Read more";
    }
  }

  return (
    <div className="bg-primary-light pb-48">
      <Helmet>
        <meta name="description" content="Bumbi description." />
      </Helmet>

      {/* Modal konfirmasi success add to cart */}
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 mt-16 flex items-start px-4 py-6 sm:items-start"
      >
        <div className="flex w-full flex-col items-center space-y-4 bg-primary-light sm:items-start">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full overflow-hidden rounded-lg bg-success-100 shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 flex w-0 flex-1 items-center justify-between">
                    <p className="text-sm font-medium text-dark-900">
                      Successfully added to cart!
                    </p>
                    {/* <p className="mt-1 text-sm text-dark-500">
                      Anyone with a link can now view this file.
                    </p> */}
                    <a
                      href="/cart"
                      className="ml-3 flex-shrink-0 rounded-md text-sm font-medium text-black underline hover:text-black focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2"
                    >
                      View Cart
                    </a>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md text-dark-400 hover:text-dark-500 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-dark-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-primary-light py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-normal uppercase leading-6 text-dark-900">
                            More Info
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="bg-primary-light text-dark-400 hover:text-dark-500 focus:outline-none focus:ring-2 focus:ring-dark-500 focus:ring-offset-2"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 pb-24 sm:px-6">
                        {/* Your content */}
                        <p className="text-xs font-light">SHIPPING Bumbi</p>
                        <div className="mt-8 text-xs font-light">
                          <h2 className="uppercase">Measurement Guide</h2>
                          <MeasurementGuideContent />
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {is_loading ? (
        <div className="flex justify-center">
          <img className="max-w-md" src={ClientLogo} alt="Logo Bumbi" />
        </div>
      ) : (
        <main className="mx-auto max-w-7xl md:px-6 lg:px-8">
          <nav className="flex px-6 py-4 md:px-0" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-4">
              <li>
                <div>
                  <a href="/" className="text-dark-400 hover:text-dark-500">
                    <HomeIcon
                      className="h-5 w-5 flex-shrink-0"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Home</span>
                  </a>
                </div>
              </li>
              <li key={product?.name}>
                <div className="flex items-center">
                  <ChevronRightIcon
                    className="h-3 w-3 flex-shrink-0 text-dark-400"
                    aria-hidden="true"
                  />
                  <a
                    href="/products"
                    className="ml-4 text-xs font-light text-dark-500 hover:text-dark-700"
                    aria-current="page"
                  >
                    Products
                  </a>
                </div>
              </li>
              <li key={product?.name}>
                <div className="flex items-center">
                  <ChevronRightIcon
                    className="h-3 w-3 flex-shrink-0 text-dark-400"
                    aria-hidden="true"
                  />
                  <a
                    href="#"
                    className="ml-4 text-xs font-light text-dark-500 hover:text-dark-700"
                    aria-current="page"
                  >
                    {product?.name}
                  </a>
                </div>
              </li>
            </ol>
          </nav>
          <div className="mx-auto max-w-7xl md:pt-8 lg:max-w-none">
            {/* Product */}
            <div className="md:grid md:grid-cols-10 lg:items-start lg:gap-x-8">
              {/* Image gallery */}
              <div className="group flex h-full w-full items-start justify-center md:col-span-5 md:justify-end">
                <div className="flex max-h-screen snap-x snap-mandatory overflow-x-hidden whitespace-nowrap md:block md:max-h-96 md:snap-y md:snap-mandatory md:overflow-y-hidden md:pr-4 lg:max-h-[600px]">
                  <img
                    src={selectedProduct?.product_image}
                    alt=""
                    className="product-image-max-height aspect-1 w-fit object-cover object-center lg:object-contain"
                  />
                </div>
                {/* <div className="ml-4 hidden max-h-screen w-40 snap-x snap-mandatory overflow-x-auto whitespace-nowrap opacity-0 transition delay-150 duration-300 ease-in-out group-hover:opacity-100 md:max-h-96 md:snap-y md:snap-mandatory md:overflow-y-auto md:pr-4 lg:block lg:max-h-[600px]">
                  {selectedProduct?.product_images?.map((image) => (
                    <img
                      src={image}
                      alt=""
                      className="product-image-max-height w-fit snap-center object-contain object-center md:max-h-96 md:snap-start lg:max-h-[600px]"
                    />
                  ))}
                </div> */}
              </div>

              {/* Product info */}
              <div className="px-4 py-2 font-light md:col-span-5 lg:mt-0">
                <h2 className="mb-2 text-base font-medium tracking-tight text-primary-dark">
                  {product?.name}
                </h2>

                <div className="mt-0">
                  <h2 className="sr-only">Product information</h2>
                  {sizeVariant ? (
                    <div className="flex gap-2 text-xl">
                      <span className="text-sm">
                        {Number(sizeVariant.discount) <
                        Number(sizeVariant.price) ? (
                          <>
                            <span>
                              {sizeVariant.discount
                                ? sizeVariant.discount.toLocaleString()
                                : ""}
                              {" IDR "}
                            </span>
                            <span className="text-sm tracking-tight text-dark-400 line-through">
                              {sizeVariant.price
                                ? sizeVariant.price.toLocaleString() + " IDR"
                                : ""}
                            </span>
                          </>
                        ) : (
                          <span>
                            {sizeVariant.price
                              ? sizeVariant.price.toLocaleString() + " IDR"
                              : ""}
                          </span>
                        )}
                      </span>
                    </div>
                  ) : (
                    <p className="text-lg font-semibold tracking-tight text-primary-600">
                      IDR {/* Tampilkan DISCOUNT jika ada */}
                      {/* {product?.discount > 0 &&
                      product?.discount < product?.max_price ? (
                        <span className="pr-1 font-semibold text-danger-600 line-through">
                          {product?.max_price.toLocaleString()}
                        </span>
                      ) : (
                        ""
                      )} */}
                      {/* Tampilkan Harga Grosir jika memenuhi syarat, jika tidak, tampilkan Harga Biasa */}
                      {/* {product?.gross_qty > 0 && product?.gross_qty <= qty
                        ? `${product?.gross_price.toLocaleString()}`
                        : `${product?.min_price.toLocaleString()}`} */}
                      {selectedProduct?.min_price.toLocaleString()}
                    </p>
                  )}
                </div>

                {/* <div className="mt-6">
                  <h3 className="text-xs font-medium pb-1 text-dark-600">Spesifikasi</h3>

                  <div
                    className="space-y-6 text-sm font-light normal-case text-dark-700"
                    // dangerouslySetInnerHTML={{ __html: product?.description }}
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {product?.spesification}
                  </div>
                </div> */}

                <div className="mt-2">
                  <h3 className="sr-only">Description</h3>

                  <div
                    name="txtDescription"
                    id="txtDescription"
                    className="line-clamp-6 space-y-6 text-sm font-light normal-case text-dark-700"
                    // dangerouslySetInnerHTML={{ __html: product?.description }}
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {product?.description}
                  </div>
                  <button
                    onClick={toggleText}
                    id="btnShowMore"
                    class="mt-3 text-xs font-medium text-primary-600 duration-300 hover:text-primary-700"
                  >
                    Read more
                  </button>
                </div>

                <div className="mt-6">
                  {/* Size picker */}
                  <div className="mt-4 hidden">
                    <div className="flex items-center justify-between">
                      {/* <h2 className="text-xs font-light text-dark-900">
                        {product?.has_variant && `Pilih Varian`}
                      </h2> */}
                      {/* <a href="#" className="text-xs font-light text-primary-600 hover:text-primary-500">
                          See sizing chart
                        </a> */}
                    </div>

                    <RadioGroup
                      value={selectedVariant}
                      onChange={setVariant}
                      className="mt-2"
                    >
                      <RadioGroup.Label className="sr-only">
                        {" "}
                        Choose Variant{" "}
                      </RadioGroup.Label>
                      <div className="flex flex-col border-y border-dark-400">
                        {product &&
                          product?.ProductColorSizes.map((variant) => (
                            <RadioGroup.Option
                              key={variant.id}
                              value={variant}
                              className={({ active, checked }) =>
                                classNames(
                                  // variant.inStock ? 'cursor-pointer focus:outline-none' : 'opacity-25 cursor-not-allowed',
                                  active ? "" : "",
                                  checked
                                    ? "bg-dark-100 font-medium"
                                    : " text-dark-900 ",
                                  // variant.inStock
                                  //   ? "text-dark-900 hover:bg-dark-100"
                                  //   : "text-dark-400",
                                  "justify-left flex cursor-pointer items-center px-3 py-1 text-xs uppercase hover:bg-dark-200 focus:outline-none sm:flex-1"
                                )
                              }
                              // disabled={!variant.inStock}
                            >
                              <RadioGroup.Label as="span">
                                {variant.name}
                              </RadioGroup.Label>
                            </RadioGroup.Option>
                          ))}
                      </div>
                    </RadioGroup>
                  </div>

                  <div className="mt-4 hidden">
                    <RadioGroup
                      value={sizeVariant}
                      onChange={setSizeVariant}
                      className="mt-2"
                    >
                      <RadioGroup.Label className="sr-only">
                        {" "}
                        Choose Variant{" "}
                      </RadioGroup.Label>
                      <div className="flex flex-col border-y border-dark-400">
                        {selectedVariant?.sizes
                          .sort(function (a, b) {
                            return a.index - b.index;
                          })
                          .map((size) => (
                            <RadioGroup.Option
                              key={size.id}
                              value={size}
                              className={({ active, checked }) =>
                                classNames(
                                  // variant.inStock ? 'cursor-pointer focus:outline-none' : 'opacity-25 cursor-not-allowed',
                                  active ? "" : "",
                                  checked ? "bg-dark-100 font-medium" : " ",
                                  size.stock > 0
                                    ? "text-dark-900 hover:bg-dark-200"
                                    : "text-dark-400",
                                  "justify-left flex cursor-pointer items-center px-3 py-1 text-xs uppercase focus:outline-none sm:flex-1"
                                )
                              }
                              disabled={!size.stock > 0 && !product?.is_po}
                            >
                              <RadioGroup.Label
                                as="span"
                                className="flex w-full justify-between"
                              >
                                <span>{size.name}</span>
                                <span>
                                  {Number(size.stock) <= 0
                                    ? "Out of Stock"
                                    : ``}
                                </span>
                              </RadioGroup.Label>
                            </RadioGroup.Option>
                          ))}
                      </div>
                    </RadioGroup>
                  </div>

                  {/* <div className="mt-4">
                    <h2 className="text-xs font-light text-dark-900">
                      {product?.has_variant && `Quantity`}
                    </h2>
                    <div className="mt-2 flex items-center gap-1">
                      <button
                        type="button"
                        className="h-6 w-6 text-primary-600 hover:text-primary-500"
                        onClick={() => setQty(qty - 1)}
                      >
                        <MinusCircleIcon className="h-6 w-6 stroke-1" />
                      </button>
                      <input
                        id="qty"
                        name="qty"
                        type="number"
                        value={qty}
                        className="block w-16 appearance-none rounded-md border border-primary-dark px-3 py-2 text-right placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-xs"
                        onChange={(e) => {
                          setQty(e.target.value);
                        }}
                        disabled={false}
                      />
                      <button
                        type="button"
                        className="h-6 w-6 text-primary-600 hover:text-primary-500"
                        onClick={() => setQty(Number(qty) + 1)}
                      >
                        <PlusCircleIcon className="h-6 w-6 stroke-1" />
                      </button>
                    </div>
                  </div> */}
                  <div className="mt-8 flex flex-col gap-2">
                    <a
                      className="flex w-full flex-1 cursor-pointer items-center justify-center rounded-md border border-emerald-500 bg-emerald-600 px-8 py-3 text-xs font-medium uppercase text-emerald-50 shadow-lg hover:border-emerald-400 hover:bg-emerald-500 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 focus:ring-offset-emerald-50 sm:w-full md:max-w-sm"
                      href={selectedProduct?.tokpedURL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Buy from Tokopedia
                    </a>
                    <a
                      className="flex w-full flex-1 cursor-pointer items-center justify-center rounded-md border border-orange-400 bg-orange-500 px-8 py-3 text-xs font-medium uppercase text-orange-50 shadow-lg hover:border-orange-400 hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 focus:ring-offset-orange-50 sm:w-full md:max-w-sm"
                      href={selectedProduct?.shopeeURL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Buy from Shopee
                    </a>
                    {/* {qty <= selectedVariantStock ? (
                      <DefaultButton
                        onClick={updateCartData}
                        // disabled={cart_update || !sizeVariant}
                        name={cart_update ? "Processing..." : "Add to Cart"}
                      ></DefaultButton>
                    ) : (
                      <DefaultButton
                        onClick={updateCartData}
                        disabled={cart_update || !sizeVariant}
                        name={
                          cart_update ? "Processing..." : "Pre Order (30 day)"
                        }
                      ></DefaultButton>
                    )} */}

                    <a
                      href={
                        "https://wa.me/6281231520688?text=Saya%20ingin%20tanya%20mengenai%20produk%20ini:%20" +
                        product?.name
                      }
                      className="flex w-fit flex-1 items-center justify-center gap-1 rounded-md py-2 text-xs font-light text-primary-600 hover:underline focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-primary-50"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ChatBubbleOvalLeftIcon
                        className="h-6 w-6 flex-shrink-0"
                        aria-hidden="true"
                      />
                      <span className="">Enquire via WhatsApp</span>
                    </a>
                  </div>
                </div>

                <section aria-labelledby="details-heading" className="mt-12">
                  <h2 id="details-heading" className="sr-only">
                    Additional details
                  </h2>

                  <button
                    className="hidden text-xs font-light uppercase"
                    onClick={() => setOpen(true)}
                  >
                    More info
                  </button>
                </section>
              </div>
            </div>
          </div>
        </main>
      )}
    </div>
  );
}
