import axios from "axios";
import { api } from "../../configs/api";

export const autoLogin = (nav) => {
  return async (dispatch) => {
    try {
      console.log("auto login");
      const token = localStorage.getItem("token");
      if (token) {
        const { data: response } = await axios({
          method: "GET",
          url: `${api}/api/v1/auth/auto-login`,
          headers: {
            authorization: `Bearer ${token}`,
          },
        });

        if (response && response.status == "success") {
          await dispatch({
            type: "AUTO_LOGIN_SUCCESS",
            payload: response.data.user_info,
          });
        }
      } else {
        // token not found
        console.log("dont have any token");
        // nav('/auth/')
      }
    } catch (error) {
      localStorage.removeItem("token");
      await nav("/auth/");
      await dispatch({ type: "AUTO_LOGIN_ERROR", error });
    }
  };
};

export const authLogin = (loginInput, nav, from) => {
  return async (dispatch) => {
    try {
      console.log("run login");
      const { data: response } = await axios({
        method: "POST",
        url: `${api}/api/v1/auth`,
        data: loginInput,
      });

      if (response && response.status == "success") {
        await localStorage.setItem("token", response.data.token);
        await dispatch(autoLogin(nav));
        // await nav("/");
        if (from !== undefined && from !== null) {
          await nav(from);
        } else {
          await nav("/");
        }
      }
    } catch (error) {
      dispatch({ type: "LOGIN_ERROR", error });
    }
  };
};

export const authLoginFashion = (loginInput, nav, from) => {
  return async (dispatch) => {
    try {
      console.log("run login");
      const { data: response } = await axios({
        method: "POST",
        url: `${api}/api/v1/auth`,
        data: loginInput,
      });

      if (response && response.status == "success") {
        await dispatch({ type: "SAVE_TOKEN", token: response.data.token });
        await localStorage.setItem("token", response.data.token);
        await dispatch(autoLogin(nav));
        // await nav("/");
        if (from !== undefined && from !== null) {
          await nav(from);
        } else {
          await nav("/");
        }
      }
    } catch (error) {
      return dispatch({ type: "LOGIN_ERROR", error });
    }
  };
};
