import { Helmet } from "react-helmet-async";
import { useTitle } from "../helper/functions";
import { pageTitle } from "../helper/globals";
import ProductImage from "../images/company/IMG_6243.webp"
import MotherImage from "../images/company/IMG_6552.webp";
import DiaperImage from "../images/company/IMG_6525.webp";
import Team1 from "../images/team/1.webp";
import Team2 from "../images/team/2.webp";
import Team3 from "../images/team/3.webp";
import Team4 from "../images/team/4.webp";
import Team5 from "../images/team/5.webp";
import Team6 from "../images/team/6.webp";
import Team7 from "../images/team/7.webp";
import Team8 from "../images/team/8.webp";
import Team9 from "../images/team/9.webp";
import Team10 from "../images/team/10.webp";
import Team11 from "../images/team/11.webp";
import Team12 from "../images/team/12.webp";
import Team13 from "../images/team/13.webp";
import Team14 from "../images/team/14.webp";
import Team15 from "../images/team/15.webp";
import Team16 from "../images/team/16.webp";
import Team17 from "../images/team/17.webp";
import LazyImage from "../components/lazyImage";

const c_level = [
  {
    name: "Celia Siura",
    role: "Founder & CEO",
    imageUrl: Team1,
  },
]

const people = [
  {
    name: "Ani Yulika",
    role: "Production Manager",
    imageUrl: Team2,
  },
  {
    name: "Florencia Angela",
    role: "Operation Officer",
    imageUrl: Team3,
  },
  {
    name: "Shem Jaron Firelio",
    role: "Partnership Officer",
    imageUrl: Team4,
  },
  {
    name: "Kenn Prasetyo",
    role: "Bus. Dev Officer",
    imageUrl: Team5,
  },
  {
    name: "Fernando Charley",
    role: "Campaign Officer",
    imageUrl: Team6,
  },
  {
    name: "Michele Swandy",
    role: "Visual Communication Design Officer",
    imageUrl: Team7,
  },
  {
    name: "Tamara Meiliana",
    role: "Project Coordinator",
    imageUrl: Team8,
  },
  {
    name: "Rizqy Salsabilah",
    role: "Field Assistant",
    imageUrl: Team9,
  },
  {
    name: "Dosni Rosianti",
    role: "Field Assistant",
    imageUrl: Team10,
  },
  {
    name: "Risto Priya",
    role: "Marketing & Sales",
    imageUrl: Team11,
  },
  {
    name: "Linawati",
    role: "Finance Officer",
    imageUrl: Team12,
  },
  {
    name: "Dwi Heru",
    role: "Production Officer",
    imageUrl: Team13,
  },
  {
    name: "Talitha Damayanti",
    role: "Project Coordinator",
    imageUrl: Team14,
  },
  {
    name: "Trie Dinda",
    role: "Field Assistant",
    imageUrl: Team15,
  },
];

const advisory_board = [
  {
    name: "Lany Harjanti",
    role: "Advisor",
    imageUrl: Team16,
  },
  {
    name: "Arzetti Bilbina",
    role: "Advisor",
    imageUrl: Team17,
  },
];

export default function AboutUs() {
  useTitle("About Us" + pageTitle);

  return (
    <div className="bg-primary-light">
      <Helmet>
        <meta name="description" content="Bumbi" />
      </Helmet>
      <section className="relative isolate overflow-hidden px-6 py-24 sm:py-32 lg:px-8">
        <LazyImage
          src={ProductImage}
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover blur grayscale"
        />
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-primary-700 sm:text-4xl">
            About Bumbi
          </h2>
          {/* <p className="mt-4 text-lg leading-8 text-primary-700">
            Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui
            lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat
            fugiat aliqua.
          </p> */}
        </div>
      </section>

      <section className="mx-auto max-w-3xl px-6 py-12 text-center text-primary-700">
        <h3 className="text-pretty text-xl font-bold">
          The First Circular Diapers
        </h3>
        <p className="mt-4 text-pretty">
          Bumbi is at the forefront of environmental sustainability and social impact, driven by innovation and a steadfast commitment. Established to confront the critical issue of plastic pollution in Indonesia's rivers—where single-use diapers and menstrual pads account for half of the debris—Bumbi leads the initiative with its sustainable, high-quality reusable products. These eco-friendly reusable diapers and menstrual pads, not only diminish plastic waste and CO2 emissions but also bolster community health and ecosystems at an affordable price.
        </p>
        <p className="mt-4 text-pretty">
          Bumbi's dynamic production model serves as a beacon of empowerment, offering meaningful employment opportunities to local women and individuals with disabilities. In partnership with local health professionals, Bumbi promotes community-wide sustainable practices and exemplifies the principles of the circular economy.
        </p>
        <p className="mt-4 text-pretty">
          Focused on driving economic growth, mitigating pollution, and enhancing water quality, Bumbi actively supports 10 of the 17 Sustainable Development Goals (SDGs), achieving substantial environmental and socio-economic benefits.
        </p>
        <p className="mt-4 text-pretty">
          This comprehensive strategy solidifies Bumbi's status as a leader in environmental innovation and a pioneer in social responsibility.
        </p>
      </section>

      {/* <section className="relative isolate overflow-hidden px-6 py-12 sm:py-16 lg:px-8">
        <img
          src={ProductImage}
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover blur"
        />
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-primary-700 sm:text-4xl">
            Our Mission
          </h2>
        </div>
      </section> */}

      <section className="bg-white dark:bg-gray-900">
        <div className="mx-auto max-w-screen-xl space-y-12 px-4 py-8 sm:py-16 lg:space-y-20 lg:px-6">
          <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
            <div className="text-gray-500 dark:text-gray-400 sm:text-lg">
              <h2 className="mb-4 text-4xl font-extrabold tracking-tight text-primary-700 dark:text-white">
                Vision
              </h2>
              <p className="mb-8 font-light lg:text-xl">
                Our vision is to revolutionize baby and adult diapering and menstrual care by providing eco- friendly and reusable alternatives to single-use products, ensuring the highest standards of quality without compromising on affordability.
              </p>
            </div>
            <LazyImage
              className="mb-4 hidden w-full rounded-lg lg:mb-0 lg:flex"
              src={DiaperImage}
              alt="Bumbi Vision for mother and planet"
            />
          </div>
          <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
            <LazyImage
              className="mb-4 hidden w-full rounded-lg lg:mb-0 lg:flex"
              src={MotherImage}
              alt="Bumbi Mission for mother and planet"
            />
            <div className="text-gray-500 dark:text-gray-400 sm:text-lg">
              <h2 className="mb-4 text-4xl font-extrabold tracking-tight text-primary-700 dark:text-white">
                Mission
              </h2>
              <p className="mb-8 font-light lg:text-xl">
                Protect Indonesia`s main water sources by combating plastic
                pollution, especially from single – use diapers responsible for
                50% of the issue, while also providing jobs for women and
                disadvantage people.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto lg:mx-0">
              <h2 className="text-3xl font-bold tracking-tight text-primary-dark sm:text-4xl text-center text-balance">
                Meet Our Dynamic Team!
              </h2>
            </div>
            <div className="mt-20 flex justify-center">
              <div className="flex flex-col items-center">
                <LazyImage
                  className="mx-auto h-28 w-28 rounded-full"
                  src={c_level[0].imageUrl}
                  alt=""
                />
                <h3 className="mt-2 text-base font-semibold leading-7 tracking-tight text-primary-dark">
                  {c_level[0].name}
                </h3>
                <p className="text-sm leading-2 text-primary-dark tracking-tight">
                  {c_level[0].role}
                </p>
              </div>
            </div>
            <ul
              className="mx-auto mt-8 flex flex-wrap justify-center max-w-2xl gap-x-8 gap-y-8 text-center lg:mx-0 lg:max-w-none"
            >
              {people.map((person) => (
                <li key={person.name} className="w-36">
                  <LazyImage
                    className="mx-auto h-28 w-28 rounded-full"
                    src={person.imageUrl}
                    alt=""
                  />
                  <h3 className="mt-2 text-base font-semibold leading-7 tracking-tight text-primary-dark">
                    {person.name}
                  </h3>
                  <p className="text-sm leading-2 text-primary-dark tracking-tight">
                    {person.role}
                  </p>
                </li>
              ))}
            </ul>
            <div className="mx-auto lg:mx-0 mt-20">
              <h2 className="text-3xl font-bold tracking-tight text-primary-dark sm:text-4xl text-center text-balance">
                Advisory Board
              </h2>
            </div>
            <ul
              className="mx-auto mt-8 flex flex-wrap justify-center max-w-2xl gap-x-8 gap-y-8 text-center lg:mx-0 lg:max-w-none"
            >
              {advisory_board.map((person) => (
                <li key={person.name} className="w-36">
                  <LazyImage
                    className="mx-auto h-28 w-28 rounded-full"
                    src={person.imageUrl}
                    alt=""
                  />
                  <h3 className="mt-2 text-base font-semibold leading-7 tracking-tight text-primary-dark">
                    {person.name}
                  </h3>
                  <p className="text-sm leading-2 text-primary-dark tracking-tight">
                    {person.role}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}
