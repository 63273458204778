import React, { useState } from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import Navbar from "./components/navbar";
import Auth from "./pages/Auth";
import CartPage from "./pages/cart";
import CheckoutPage from "./pages/checkout";
import Home from "./pages/home";
import Index from "./pages/index";

import { Provider } from "react-redux";
import { Helmet, HelmetProvider } from "react-helmet-async";
import store from "./redux/store";
import Products from "./pages/products";
import Footer from "./components/footer";
import UnderConstruction from "./pages/underConstruction";

function App() {
  const [hasToken, setHasToken] = useState(false);

  return (
    <>
      {
        <HelmetProvider>
          <Provider store={store}>
            <BrowserRouter>
              {/* <Navbar /> */}
              <Routes>
                {
                  <Route path="/*" element={<Outlet />}>
                    <Route index element={<Index />} />
                    {/* <Route path="auth/*" element={<Auth />} /> */}
                    <Route path="*" element={<Index />} />
                    {/* <Route path="cart/" element={<CartPage />} />
                    <Route path="checkout/" element={<CheckoutPage />} /> */}
                    <Route path="under-construction/" element={<UnderConstruction />} />
                  </Route>
                }
              </Routes>
              {/* <Footer /> */}
            </BrowserRouter>
          </Provider>
        </HelmetProvider>
      }
    </>
  );
}

export default App;
