import { ChevronUpIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatCurrency, productPrice } from "../helper/functions";
import { getCategory, getProduct } from "../redux/actions/productsAction";
import { useTitle } from "../helper/functions";
import { pageTitle } from "../helper/globals";
import { Helmet } from "react-helmet-async";
import { useSearchParams } from "react-router-dom";
import LazyImage from "../components/lazyImage";
import ClientLogo from "../images/bumbi-logo.webp"
import BlueFront from "../images/company/products/blue-front.webp";
import BrownFront from "../images/company/products/brown-front.webp";
import DarkFront from "../images/company/products/dark-front.webp";
import GreyFront from "../images/company/products/grey-front.webp";
import NavyFront from "../images/company/products/navy-front.webp";
import OrangeFront from "../images/company/products/orange-front.webp";
import PinkFront from "../images/company/products/pink-front.webp";
import RedFront from "../images/company/products/red-front.webp";
import YellowFront from "../images/company/products/yellow-front.webp";
import BlueBack from "../images/company/products/blue-back.webp";
import BrownBack from "../images/company/products/brown-back.webp";
import DarkBack from "../images/company/products/dark-back.webp";
import GreyBack from "../images/company/products/grey-back.webp";
import NavyBack from "../images/company/products/navy-back.webp";
import OrangeBack from "../images/company/products/orange-back.webp";
import PinkBack from "../images/company/products/pink-back.webp";
import RedBack from "../images/company/products/red-back.webp";
import YellowBack from "../images/company/products/yellow-back.webp";
import PantsBabyBluefront from "../images/company/products/pants-BabyBlue-front.webp";
import PantsBlackfront from "../images/company/products/pants-Black-front.webp";
import Pantsblueback from "../images/company/products/pants-blue-back.webp";
import PantsDarkKhakifront from "../images/company/products/pants-DarkKhaki-front.webp";
import PantsDarkSlateGrayfront from "../images/company/products/pants-DarkSlateGray-front.webp";
import PantsGrayfront from "../images/company/products/pants-Gray-front.webp";
import PantsIndianRedback from "../images/company/products/pants-IndianRed-back.webp";
import PantsLavenderback from "../images/company/products/pants-Lavender-back.webp";
import PantsLightPinkfront from "../images/company/products/pants-LightPink-front.webp";
import PantsLightSalmonback from "../images/company/products/pants-LightSalmon-back.webp";
import PantsLightSkyBlueback from "../images/company/products/pants-LightSkyBlue-back.webp";
import PantsLightSlateGrayfront from "../images/company/products/pants-LightSlateGray-front.webp";
import PantsLightSteelBlueback from "../images/company/products/pants-LightSteelBlue-back.webp";
import PantsMidnightBlueback from "../images/company/products/pants-MidnightBlue-back.webp";
import Pantsnavyback from "../images/company/products/pants-navy-back.webp";
import PantsOrangeRedback from "../images/company/products/pants-OrangeRed-back.webp";
import Pantspinkback from "../images/company/products/pants-pink-back.webp";
import PantsPlumback from "../images/company/products/pants-Plum-back.webp";
import PantspolkadotBluefront from "../images/company/products/pants-polkadotBlue-front.webp";
import PantspolkadotGreenfront from "../images/company/products/pants-polkadotGreen-front.webp";
import PantsPolkadotPinkback from "../images/company/products/pants-PolkadotPink-back.webp";
import PantsRosyBrownback from "../images/company/products/pants-RosyBrown-back.webp";
import PantsSeaGreenfront from "../images/company/products/pants-SeaGreen-front.webp";
import PantsSilverback from "../images/company/products/pants-Silver-back.webp";
import PantsSkyBluefront from "../images/company/products/pants-SkyBlue-front.webp";
import PantsWhiteColorfulback from "../images/company/products/pants-WhiteColorful-back.webp";

export default function Products() {
  const [searchParams] = useSearchParams();
  // const products = useSelector((state) => state.products.product_list);

  const products = [
    {
      id: 1,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pocket)",
      product_image: BlueFront,
      alt_image: BlueBack,
      product_images: [BlueFront, BlueBack],
      DetailImage: null,
      inStock: true,
      min_price: 100000,
      max_price: 100000,
      url: "/products/detail/1",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797417#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-pocket-clodi-bahan-premium-murah-common-seas-pc01-armygreen-poly-micro?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 2,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pocket)",
      product_image: BrownFront,
      alt_image: BrownBack,
      product_images: [BrownFront, BrownBack],
      DetailImage: null,
      inStock: true,
      min_price: 100000,
      max_price: 100000,
      url: "/products/detail/2",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797417#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-pocket-clodi-bahan-premium-murah-common-seas-pc01-armygreen-poly-micro?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 3,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pocket)",
      product_image: DarkFront,
      alt_image: DarkBack,
      product_images: [DarkFront, DarkBack],
      DetailImage: null,
      inStock: true,
      min_price: 100000,
      max_price: 100000,
      url: "/products/detail/3",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797417#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-pocket-clodi-bahan-premium-murah-common-seas-pc01-armygreen-poly-micro?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 4,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pocket)",
      product_image: GreyFront,
      alt_image: GreyBack,
      product_images: [GreyFront, GreyBack],
      DetailImage: null,
      inStock: true,
      min_price: 100000,
      max_price: 100000,
      url: "/products/detail/4",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797417#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-pocket-clodi-bahan-premium-murah-common-seas-pc01-armygreen-poly-micro?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 5,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pocket)",
      product_image: NavyFront,
      alt_image: NavyBack,
      product_images: [NavyFront, NavyBack],
      DetailImage: null,
      inStock: true,
      min_price: 100000,
      max_price: 100000,
      url: "/products/detail/5",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797417#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-pocket-clodi-bahan-premium-murah-common-seas-pc01-armygreen-poly-micro?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 6,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pocket)",
      product_image: OrangeFront,
      alt_image: OrangeBack,
      product_images: [OrangeFront, OrangeBack],
      DetailImage: null,
      inStock: true,
      min_price: 100000,
      max_price: 100000,
      url: "/products/detail/6",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797417#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-pocket-clodi-bahan-premium-murah-common-seas-pc01-armygreen-poly-micro?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 7,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pocket)",
      product_image: PinkFront,
      alt_image: PinkBack,
      product_images: [PinkFront, PinkBack],
      DetailImage: null,
      inStock: true,
      min_price: 100000,
      max_price: 100000,
      url: "/products/detail/7",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797417#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-pocket-clodi-bahan-premium-murah-common-seas-pc01-armygreen-poly-micro?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 8,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pocket)",
      product_image: RedFront,
      alt_image: RedBack,
      product_images: [RedFront, RedBack],
      DetailImage: null,
      inStock: true,
      min_price: 100000,
      max_price: 100000,
      url: "/products/detail/8",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797417#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-pocket-clodi-bahan-premium-murah-common-seas-pc01-armygreen-poly-micro?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 9,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pocket)",
      product_image: YellowFront,
      alt_image: YellowBack,
      product_images: [YellowFront, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 100000,
      max_price: 100000,
      url: "/products/detail/9",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797417#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-pocket-clodi-bahan-premium-murah-common-seas-pc01-armygreen-poly-micro?extParam=src%3Dshop%26whid%3D13716804",
    },
  ];

  const products_pants = [
    {
      id: 11,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsBabyBluefront,
      alt_image: null,
      product_images: [PantsBabyBluefront],
      DetailImage: null,
      inStock: true,
      min_price: 83000,
      max_price: 83000,
      url: "/products/detail/1",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797427#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-celana-clodi-bahan-premium-murah-common-seas-microfleece-pn-01-aqua?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 12,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsBlackfront,
      alt_image: BrownBack,
      product_images: [PantsBlackfront, BrownBack],
      DetailImage: null,
      inStock: true,
      min_price: 83000,
      max_price: 83000,
      url: "/products/detail/2",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797427#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-celana-clodi-bahan-premium-murah-common-seas-microfleece-pn-01-aqua?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 13,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: Pantsblueback,
      alt_image: DarkBack,
      product_images: [Pantsblueback, DarkBack],
      DetailImage: null,
      inStock: true,
      min_price: 83000,
      max_price: 83000,
      url: "/products/detail/3",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797427#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-celana-clodi-bahan-premium-murah-common-seas-microfleece-pn-01-aqua?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 14,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsDarkKhakifront,
      alt_image: GreyBack,
      product_images: [PantsDarkKhakifront, GreyBack],
      DetailImage: null,
      inStock: true,
      min_price: 83000,
      max_price: 83000,
      url: "/products/detail/4",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797427#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-celana-clodi-bahan-premium-murah-common-seas-microfleece-pn-01-aqua?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 15,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsDarkSlateGrayfront,
      alt_image: NavyBack,
      product_images: [PantsDarkSlateGrayfront, NavyBack],
      DetailImage: null,
      inStock: true,
      min_price: 83000,
      max_price: 83000,
      url: "/products/detail/5",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797427#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-celana-clodi-bahan-premium-murah-common-seas-microfleece-pn-01-aqua?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 16,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsGrayfront,
      alt_image: OrangeBack,
      product_images: [PantsGrayfront, OrangeBack],
      DetailImage: null,
      inStock: true,
      min_price: 83000,
      max_price: 83000,
      url: "/products/detail/6",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797427#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-celana-clodi-bahan-premium-murah-common-seas-microfleece-pn-01-aqua?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 17,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsIndianRedback,
      alt_image: PinkBack,
      product_images: [PantsIndianRedback, PinkBack],
      DetailImage: null,
      inStock: true,
      min_price: 83000,
      max_price: 83000,
      url: "/products/detail/7",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797427#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-celana-clodi-bahan-premium-murah-common-seas-microfleece-pn-01-aqua?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 18,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsLavenderback,
      alt_image: RedBack,
      product_images: [PantsLavenderback, RedBack],
      DetailImage: null,
      inStock: true,
      min_price: 83000,
      max_price: 83000,
      url: "/products/detail/8",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797427#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-celana-clodi-bahan-premium-murah-common-seas-microfleece-pn-01-aqua?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 19,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsLightPinkfront,
      alt_image: YellowBack,
      product_images: [PantsLightPinkfront, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 83000,
      max_price: 83000,
      url: "/products/detail/9",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797427#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-celana-clodi-bahan-premium-murah-common-seas-microfleece-pn-01-aqua?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 20,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsLightSalmonback,
      alt_image: YellowBack,
      product_images: [PantsLightSalmonback, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 83000,
      max_price: 83000,
      url: "/products/detail/9",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797427#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-celana-clodi-bahan-premium-murah-common-seas-microfleece-pn-01-aqua?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 21,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsLightSkyBlueback,
      alt_image: YellowBack,
      product_images: [PantsLightSkyBlueback, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 83000,
      max_price: 83000,
      url: "/products/detail/9",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797427#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-celana-clodi-bahan-premium-murah-common-seas-microfleece-pn-01-aqua?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 22,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsLightSlateGrayfront,
      alt_image: YellowBack,
      product_images: [PantsLightSlateGrayfront, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 83000,
      max_price: 83000,
      url: "/products/detail/9",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797427#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-celana-clodi-bahan-premium-murah-common-seas-microfleece-pn-01-aqua?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 23,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsLightSteelBlueback,
      alt_image: YellowBack,
      product_images: [PantsLightSteelBlueback, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 83000,
      max_price: 83000,
      url: "/products/detail/9",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797427#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-celana-clodi-bahan-premium-murah-common-seas-microfleece-pn-01-aqua?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 24,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsMidnightBlueback,
      alt_image: YellowBack,
      product_images: [PantsMidnightBlueback, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 83000,
      max_price: 83000,
      url: "/products/detail/9",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797427#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-celana-clodi-bahan-premium-murah-common-seas-microfleece-pn-01-aqua?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 25,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: Pantsnavyback,
      alt_image: YellowBack,
      product_images: [Pantsnavyback, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 83000,
      max_price: 83000,
      url: "/products/detail/9",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797427#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-celana-clodi-bahan-premium-murah-common-seas-microfleece-pn-01-aqua?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 26,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsOrangeRedback,
      alt_image: YellowBack,
      product_images: [PantsOrangeRedback, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 83000,
      max_price: 83000,
      url: "/products/detail/9",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797427#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-celana-clodi-bahan-premium-murah-common-seas-microfleece-pn-01-aqua?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 27,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: Pantspinkback,
      alt_image: YellowBack,
      product_images: [Pantspinkback, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 83000,
      max_price: 83000,
      url: "/products/detail/9",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797427#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-celana-clodi-bahan-premium-murah-common-seas-microfleece-pn-01-aqua?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 28,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsPlumback,
      alt_image: YellowBack,
      product_images: [PantsPlumback, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 83000,
      max_price: 83000,
      url: "/products/detail/9",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797427#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-celana-clodi-bahan-premium-murah-common-seas-microfleece-pn-01-aqua?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 29,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantspolkadotBluefront,
      alt_image: YellowBack,
      product_images: [PantspolkadotBluefront, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 83000,
      max_price: 83000,
      url: "/products/detail/9",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797427#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-celana-clodi-bahan-premium-murah-common-seas-microfleece-pn-01-aqua?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 30,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantspolkadotGreenfront,
      alt_image: YellowBack,
      product_images: [PantspolkadotGreenfront, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 83000,
      max_price: 83000,
      url: "/products/detail/9",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797427#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-celana-clodi-bahan-premium-murah-common-seas-microfleece-pn-01-aqua?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 31,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsPolkadotPinkback,
      alt_image: YellowBack,
      product_images: [PantsPolkadotPinkback, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 83000,
      max_price: 83000,
      url: "/products/detail/9",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797427#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-celana-clodi-bahan-premium-murah-common-seas-microfleece-pn-01-aqua?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 32,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsRosyBrownback,
      alt_image: YellowBack,
      product_images: [PantsRosyBrownback, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 83000,
      max_price: 83000,
      url: "/products/detail/9",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797427#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-celana-clodi-bahan-premium-murah-common-seas-microfleece-pn-01-aqua?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 33,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsSeaGreenfront,
      alt_image: YellowBack,
      product_images: [PantsSeaGreenfront, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 83000,
      max_price: 83000,
      url: "/products/detail/9",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797427#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-celana-clodi-bahan-premium-murah-common-seas-microfleece-pn-01-aqua?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 34,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsSilverback,
      alt_image: YellowBack,
      product_images: [PantsSilverback, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 83000,
      max_price: 83000,
      url: "/products/detail/9",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797427#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-celana-clodi-bahan-premium-murah-common-seas-microfleece-pn-01-aqua?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 35,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsSkyBluefront,
      alt_image: YellowBack,
      product_images: [PantsSkyBluefront, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 83000,
      max_price: 83000,
      url: "/products/detail/9",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797427#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-celana-clodi-bahan-premium-murah-common-seas-microfleece-pn-01-aqua?extParam=src%3Dshop%26whid%3D13716804",
    },
    {
      id: 36,
      name: "Pack of 1 Reusable cloth Diapers One Size (Pants)",
      product_image: PantsWhiteColorfulback,
      alt_image: YellowBack,
      product_images: [PantsWhiteColorfulback, YellowBack],
      DetailImage: null,
      inStock: true,
      min_price: 83000,
      max_price: 83000,
      url: "/products/detail/9",
      shopeeURL:
        "https://shopee.co.id/commonseas?shopCollection=247797427#product_list",
      tokpedURL:
        "https://www.tokopedia.com/commonseas/popok-kain-celana-clodi-bahan-premium-murah-common-seas-microfleece-pn-01-aqua?extParam=src%3Dshop%26whid%3D13716804",
    },
  ];

  const categories = useSelector((state) => state.products.category_list);
  // const is_loading = useSelector((state) => state.products.loading);
  const is_loading = false;

  const [inputValue, setInputValue] = useState("");
  const typingTimer = useRef(null);
  const doneTypingInterval = 1000;
  const dispatch = useDispatch();

  const handleKeyUp = () => {
    clearTimeout(typingTimer.current);
    typingTimer.current = setTimeout(doneTyping, doneTypingInterval);
  };

  const handleKeyDown = () => {
    clearTimeout(typingTimer.current);
  };

  const doneTyping = () => {
    dispatch(getProduct(null, inputValue));
  };

  useTitle("Latest Products" + pageTitle);

  // console.log("Category: ", searchParams.get("category"));
  // console.log("Name: ", searchParams.get("name"));
  const category = searchParams.get("category");

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "/js/animate-on-scroll.js";
    script.async = false;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    dispatch(getProduct(category));
    dispatch(getCategory());
  }, []);
  return (
    <div className="relative bg-primary-light">
      <Helmet>
        <meta name="description" content="Bumbi description." />
      </Helmet>
      {/* Mobile menu */}
      <main>
        <div className="bg-primary-light">
          <div className="m-auto mt-4 flex max-w-7xl flex-wrap items-center justify-start gap-2 px-6 lg:px-8">
            {/* {categories.length > 0 &&
              categories.map((category) => (
                <div
                  className="min-w-[100px] cursor-pointer bg-white px-4 py-1 text-center text-dark-700 hover:bg-primary-600 hover:text-light-100"
                  onClick={() => dispatch(getProduct(category.id))}
                  key={category.id}
                >
                  {category.name}
                </div>
              ))} */}
            {/* Search Bar */}
            {/* <input
              type="text"
              value={inputValue}
              placeholder="Cari produk..."
              onChange={(e) => setInputValue(e.target.value)}
              onKeyUp={handleKeyUp}
              onKeyDown={handleKeyDown}
              className="w-full border-dark-300 focus:ring-primary-500 focus:accent-primary-500 focus:border-primary-500 py-1 md:hidden"
            /> */}
          </div>
          <div className="mx-auto max-w-2xl px-6 py-4 sm:py-4 lg:max-w-7xl lg:px-8">
            <h2
              id="our-products"
              className="w-full text-center text-xl font-bold text-dark-700"
            >
              Our Products
            </h2>
            <p className="mx-auto max-w-sm py-4 text-center text-sm font-light">
              All the Protection. None of the Waste. Made from the softest
              materials and using safe ingredients.
            </p>

            {is_loading ? (
              <div className="flex justify-center">
                <img className="max-w-md" src={ClientLogo} alt="Logo Bumbi" />
              </div>
            ) : (
              <>
                <div className="divide-y divide-primary-dark/30">
                  <h3 className="text-2xl font-bold text-primary-700">
                    Pocket
                  </h3>
                  <div className="js-show-on-scroll m-auto mt-4 grid max-w-7xl grid-cols-2 items-center justify-start gap-8 sm:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6">
                    {products.map(
                      (product) =>
                        product.inStock && (
                          <>
                            <a
                              key={product.id}
                              href={`products/detail/${product.id}`}
                              className="flex justify-center"
                            >
                              <div className="relative h-full">
                                <div className="js-show-on-scroll relative flex h-full w-fit flex-col overflow-hidden transition-opacity duration-700 ease-in">
                                  <LazyImage
                                    src={
                                      product?.DetailImage === null
                                        ? `${product?.product_image}`
                                        : `${process.env.REACT_APP_BACKEND_API}/file/${product?.DetailImage?.path}`
                                    }
                                    // src="https://down-id.img.susercontent.com/file/sg-11134201-23010-ctu7hma06fmv6b"
                                    alt=""
                                    className="product-image-max-height aspect-1 w-fit object-cover object-center lg:object-cover"
                                  />
                                  <div className="flex flex-grow flex-col justify-between px-2 py-1">
                                    <div className="relative mt-0 flex items-center justify-between gap-4 px-2 py-1 sm:px-0">
                                      <h3 className="line-clamp-3 text-sm font-semibold leading-tight tracking-tight text-dark-900">
                                        {product.name}
                                      </h3>
                                    </div>
                                    {/* <p className="relative py-1 text-base font-medium text-primary-600">
                                  <span className="pr-1 text-sm">Rp</span>
                                  {productPrice(product, 1)}
                                </p> */}
                                    <a
                                      href={"/products/detail/" + product.id}
                                      className="w-full rounded-md bg-primary-700 py-1.5 text-center text-xs uppercase text-primary-light"
                                    >
                                      Buy
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </>
                        )
                    )}
                  </div>
                </div>
                <div className="mt-24 divide-y divide-primary-dark/30">
                  <h3 className="text-2xl font-bold text-primary-700">Pants</h3>
                  <div className="js-show-on-scroll m-auto mt-4 grid max-w-7xl grid-cols-2 items-center justify-start gap-8 sm:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6">
                    {products_pants.map(
                      (product) =>
                        product.inStock && (
                          <>
                            <a
                              key={product.id}
                              href={`products/detail/${product.id}`}
                              className="flex justify-center"
                            >
                              <div className="relative h-full">
                                <div className="js-show-on-scroll relative flex h-full w-fit flex-col overflow-hidden transition-opacity duration-700 ease-in">
                                  <LazyImage
                                    src={
                                      product?.DetailImage === null
                                        ? `${product?.product_image}`
                                        : `${process.env.REACT_APP_BACKEND_API}/file/${product?.DetailImage?.path}`
                                    }
                                    // src="https://down-id.img.susercontent.com/file/sg-11134201-23010-ctu7hma06fmv6b"
                                    alt=""
                                    className="product-image-max-height aspect-1 w-fit object-cover object-center lg:object-cover"
                                  />
                                  <div className="flex flex-grow flex-col justify-between px-2 py-1">
                                    <div className="relative mt-0 flex items-center justify-between gap-4 px-2 py-1 sm:px-0">
                                      <h3 className="line-clamp-3 text-sm font-semibold leading-tight tracking-tight text-dark-900">
                                        {product.name}
                                      </h3>
                                    </div>
                                    {/* <p className="relative py-1 text-base font-medium text-primary-600">
                                  <span className="pr-1 text-sm">Rp</span>
                                  {productPrice(product, 1)}
                                </p> */}
                                    <a
                                      href={"/products/detail/" + product.id}
                                      className="w-full rounded-md bg-primary-700 py-1.5 text-center text-xs uppercase text-primary-light"
                                    >
                                      Buy
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </>
                        )
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </main>
      <a
        href="#our-products"
        className="fixed bottom-4 right-4 z-50 rounded-lg bg-dark-100/50"
      >
        <ChevronUpIcon className="h-8 w-8 text-dark-500" />
        {/* Back to Top */}
      </a>
    </div>
  );
}
