import { Helmet } from "react-helmet-async";
import { useTitle } from "../helper/functions";
import { pageTitle } from "../helper/globals";
import ClientLogo from "../images/bumbi-logo.webp"
import Creature1 from "../images/creature-1.webp"
import Creature2 from "../images/creature-2.webp"
import Creature3 from "../images/creature-3.webp"
import Creature4 from "../images/creature-4.webp"
import Creature5 from "../images/creature-5.webp"

export default function UnderConstruction() {
  useTitle("Under Construction" + pageTitle);

  return (
    <div className="bg-primary-light dark:bg-gray-900 relative isolate">
      <Helmet>
        <meta
          name="description"
          content="Bumbi description."
        />
        <meta name="robots" content="noindex,nofollow"></meta>
      </Helmet>
        {/* <section class="h-screen flex items-center">
            <img
              src=""
              alt=""
              className="absolute inset-0 -z-10 h-full w-full object-cover bg-accent-light"
            />
            <div class="py-8 px-4 mx-auto max-w-screen-md text-center lg:py-16 lg:px-12 ">
                <a href="/" className="flex justify-center items-center mb-5 text-3xl font-semibold text-accent-900 dark:text-white">
                    <span className="sr-only">Bumbi</span>
                    <img src={ClientLogo} alt="Logo Bumbi" className="h-12 w-auto" />
                </a>

                <h1 class="mb-4 text-4xl font-bold tracking-tight leading-none text-gray-900 lg:mb-6 md:text-5xl xl:text-6xl dark:text-white">Coming Soon</h1>
                <p class="font-semibold text-accent-dark md:text-lg xl:text-xl dark:text-gray-400">Our website is under construction!</p>
            </div>
        </section> */}

        <section>
        <div className="relative isolate overflow-hidden h-screen flex items-center">
          <img
            src=""
            alt=""
            className="absolute inset-0 -z-10 h-full w-full object-cover bg-accent-light pattern-vertical-lines pattern-accent-dark pattern-bg-accent-light pattern-opacity-10 pattern-size-8"
          />
          <div
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            aria-hidden="true"
          >
          </div>
          <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56 relative">
            <img
              src={Creature1}
              alt=""
              className="absolute top-20 left-0 -z-10 w-20 h-20 object-contain"
            />
            <img
              src={Creature3}
              alt=""
              className="absolute top-20 right-0 rotate-12 -z-10 w-16 h-16 object-contain"
            />
            <img
              src={Creature2}
              alt=""
              className="absolute -right-32 top-1/2 rotate-12 -z-10 w-16 h-16 object-contain"
            />
            <img
              src={Creature4}
              alt=""
              className="absolute -left-32 bottom-40 -rotate-12 -z-10 w-16 h-16 object-contain"
            />
            <img
              src={Creature5}
              alt=""
              className="absolute bottom-0 right-1/3 -z-10 w-16 h-16 object-contain"
            />
            <p class="mb-4 text-sm font-bold tracking-tight leading-none text-gray-900 lg:mb-6 md:text-base xl:text-xl dark:text-white text-center">Under Construction</p>
            <div className="mb-4 sm:mb-8 flex justify-center">
              <h1 className="relative rounded-full px-3 py-1 text-xl font-bold leading-6 text-primary-dark ring-1 ring-white/10 hover:ring-white/20">
                
                  "The First Circular Diapers"
                {/* <a href="#" className="font-semibold text-white">
                  <span className="absolute inset-0" aria-hidden="true" />
                  Read more <span aria-hidden="true">&rarr;</span>
                </a> */}
              </h1>
            </div>
            <div className="text-center px-6">
              <div className="flex justify-center">
                <img src={ClientLogo} alt="Logo Bumbi" className="h-20 md:h-32" />
              </div>
              <p className="mt-6 text-2xl md:text-4xl font-semibold leading-8 text-accent-dark">
                dari <span className="text-primary-dark">bunda</span> untuk <span className="text-primary-dark">bumi</span> dan <span className="text-primary-dark">bayi</span>
              </p>
              <div className="mt-10 flex flex-col items-center justify-center gap-x-6">
                <p class="font-medium text-gray-700 md:text-lg xl:text-xl dark:text-gray-400">We’ll be back in April 2024</p>
                <p class="font-medium text-gray-700 md:text-lg xl:text-xl dark:text-gray-400">For any urgent request, please contact us at <a className="font-semibold text-gray-900" href="mailto:contact@bumbi.id">contact@bumbi.id</a> or via WhatsApp at <a
                        className="font-semibold text-gray-900"
                        href="https://wa.me/628113252200"
                        target="_blank"
                        rel="noreferrer"
                      >
                        +628113252200
                      </a>.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
